import { Form } from "@/components/ui/components/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/components/button";
import { useToast } from "@/components/ui/hooks/use-toast";
import { Params } from "@/commercetools/types/next.types";
import { useParams } from "next/navigation";
import { useMemo, useTransition } from "react";
import { AccountFormSchema, accountFormSchema } from "./schema";
import { useForm } from "react-hook-form";
import { AccountForm } from "./account.form";
import { RotateCwIcon } from "lucide-react";

export function AccountDetailsForm<T>({
	data,
	setAccountAction,
}: {
	data: {
		account: {
			firstName: string;
			lastName: string;
			email: string;
		};
		changePassword: boolean;
	};
	setAccountAction: (payload: T, params: Params) => Promise<void>;
}) {
	const [isPending, startTransition] = useTransition();
	const { toast } = useToast();
	const { locale } = useParams();

	const defaultAccountInfo = useMemo(() => {
		return data?.account
			? {
					firstName: data?.account?.firstName ?? "",
					lastName: data?.account?.lastName ?? "",
					email: data?.account?.email ?? "",
			  }
			: {
					firstName: "",
					lastName: "",
					email: "",
			  };
	}, [data]);

	const form = useForm<AccountFormSchema>({
		resolver: zodResolver(accountFormSchema),
		defaultValues: {
			account: defaultAccountInfo,
		},
	});

	const onSubmit = async (values: AccountFormSchema) => {
		try {
			const payload = {
				account: {
					firstName: values.account.firstName,
					lastName: values.account.lastName,
					email: values.account.email,
				},
			} as T;
			startTransition(async () => {
				await setAccountAction(payload, { locale });
			});
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to update addresses",
			});
		}
	};

	return (
		<Form {...form}>
			<form
				className="space-y-4 mt-8 mb-20"
				onSubmit={(e) => {
					form.handleSubmit(onSubmit)(e);
				}}
			>
				<AccountForm />
				<div className="flex items-center justify-center">
					<Button
						type="submit"
						variant="primary"
						className="asio-account-addresses__button my-8"
						disabled={isPending}
					>
						{isPending ? (
							<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
						) : (
							<span>Submit</span>
						)}
					</Button>
				</div>
			</form>
		</Form>
	);
}
