"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Typography } from "@/components/ui/components/typography";

type TasticData = {
	message: string;
	backgroundColor?: string;
};

export default function GlobalMessagingBannerTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div
			className="landing__global_messaging_banner_container"
			style={{
				backgroundColor: data?.backgroundColor
					? `#${data.backgroundColor}`
					: "#B42573",
			}}
		>
			<Typography.Text className="landing__global_messaging_banner_content">
				{data.message}
			</Typography.Text>
		</div>
	);
}
