"use client";

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from "@/components/ui/components/form";
import { useForm } from "react-hook-form";
import { AddressesFormSchema, addressesFormSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Address } from "@/shared/types/account/Address";
import { Button } from "@/components/ui/components/button";
import { useToast } from "@/components/ui/hooks/use-toast";
import { BillingAddressForm } from "./billing-address.form";
import { ShippingAddressForm } from "./shipping-address.form";
import { Params } from "@/commercetools/types/next.types";
import { useParams, usePathname } from "next/navigation";
import { useToggleState } from "@/hooks/use-toggle-state";
import { Checkbox } from "@/components/ui/components/checkbox";
import { compareAddresses } from "@/lib/address";
import { Typography } from "@/components/ui/components/typography";
import { RotateCwIcon } from "lucide-react";
import { useEffect, useMemo, useTransition } from "react";
import { CheckoutSteps } from "@/tastics/checkout/types";
import { useRouter } from "next/navigation";

export function AddressesForm<T>({
	email,
	addresses,
	setAddressesAction,
}: {
	email: string;
	addresses: {
		billing: Address | undefined;
		shipping: Address | undefined;
	};
	setAddressesAction: (payload: T, params: Params) => Promise<void>;
}) {
	const [isPending, startTransition] = useTransition();
	const router = useRouter();
	const pathname = usePathname();
	const { toast } = useToast();
	const { locale } = useParams();

	const { state: sameAddress, toggle: toggleSameAsShipping } = useToggleState(
		addresses.shipping && addresses.billing
			? compareAddresses(addresses.shipping, addresses.billing)
			: true
	);

	const defaultShippingAddress = useMemo(() => {
		return addresses?.shipping
			? {
					firstName: addresses.shipping?.firstName ?? "",
					lastName: addresses.shipping?.lastName ?? "",
					streetName: addresses.shipping?.streetName ?? "",
					streetNumber: addresses.shipping?.streetNumber ?? "",
					city: addresses.shipping?.city ?? "",
					postalCode: addresses.shipping?.postalCode ?? "",
					state: addresses.shipping?.state ?? "",
					country: addresses.shipping?.country ?? "",
			  }
			: {
					firstName: "",
					lastName: "",
					streetName: "",
					streetNumber: "",
					city: "",
					postalCode: "",
					state: "",
					country: "",
			  };
	}, [addresses]);

	const defaultBillingAddress = useMemo(() => {
		return addresses?.billing
			? {
					firstName: addresses.billing?.firstName ?? "",
					lastName: addresses.billing?.lastName ?? "",
					streetName: addresses.billing?.streetName ?? "",
					streetNumber: addresses.billing?.streetNumber ?? "",
					city: addresses.billing?.city ?? "",
					postalCode: addresses.billing?.postalCode ?? "",
					state: addresses.billing?.state ?? "",
					country: addresses.billing?.country ?? "",
			  }
			: defaultShippingAddress;
	}, [addresses, defaultShippingAddress]);

	const form = useForm<AddressesFormSchema>({
		resolver: zodResolver(addressesFormSchema),
		defaultValues: {
			shipping: defaultShippingAddress,
			billing: defaultBillingAddress,
			sameAddress: sameAddress,
		},
	});
	const { getValues, setValue } = form;

	useEffect(() => {
		if (getValues("sameAddress")) {
			setValue("billing", getValues("shipping"));
		} else {
			setValue("billing", defaultBillingAddress);
		}
	}, [sameAddress, setValue, getValues, defaultBillingAddress]);

	const onSubmit = async (values: AddressesFormSchema) => {
		try {
			const payload = {
				...values,
				account: {
					email,
				},
			} as T;

			await setAddressesAction(payload, { locale });

			startTransition(() => {
				router.replace(`${pathname}?step=${CheckoutSteps.Delivery}`);
			});
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to update addresses",
			});
		}
	};

	return (
		<Form {...form}>
			<form
				className="space-y-4 md:mx-11 md:mb-11"
				onSubmit={(e) => {
					if (getValues("sameAddress")) {
						setValue("billing", getValues("shipping"));
					}

					form.handleSubmit(onSubmit)(e);
				}}
			>
				<Typography.Heading as="h3" className="asio-form-title">
					Shipping Address
				</Typography.Heading>

				<ShippingAddressForm />

				<FormField
					control={form.control}
					name="sameAddress"
					render={({ field }) => (
						<FormItem className="flex flex-row items-center space-x-4 space-y-0">
							<FormControl>
								<Checkbox
									checked={field.value}
									onCheckedChange={() => {
										field.onChange();
										toggleSameAsShipping();
										form.setValue("sameAddress", !sameAddress);
									}}
								/>
							</FormControl>
							<div className="space-y-2 leading-none">
								<FormLabel>Same as shipping address</FormLabel>
							</div>
						</FormItem>
					)}
				/>

				{!sameAddress && (
					<>
						<Typography.Heading as="h3" className="asio-form-title">
							Billing Address
						</Typography.Heading>
						<BillingAddressForm />
					</>
				)}

				<Button
					type="submit"
					disabled={form.formState.isSubmitting}
					variant="secondary"
					className="asio-checkout-save__button"
				>
					{form.formState.isSubmitting || isPending ? (
						<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
					) : (
						<span>Save</span>
					)}
				</Button>
			</form>
		</Form>
	);
}
