"use client";

import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useMemo } from "react";

type StripeWrapperProps = {
	clientSecret: string;
	children: React.ReactNode;
};

const stripeKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;

const StripeWrapper: React.FC<StripeWrapperProps> = ({
	clientSecret,
	children,
}) => {
	const stripePromise = useMemo(() => loadStripe(stripeKey!), []);

	const options: StripeElementsOptions = {
		clientSecret,
	};

	if (!stripeKey) {
		throw new Error(
			"Stripe key is missing. Set NEXT_PUBLIC_STRIPE_KEY environment variable."
		);
	}

	if (!stripePromise) {
		throw new Error(
			"Stripe promise is missing. Make sure you have provided a valid Stripe key."
		);
	}

	if (!clientSecret) {
		throw new Error(
			"Stripe client secret is missing. Cannot initialize Stripe."
		);
	}

	return (
		<Elements options={options} stripe={stripePromise}>
			{children}
		</Elements>
	);
};

export default StripeWrapper;
