"use client";

import { Button } from "@/components/ui/components/button";
import { useUrlParams } from "@/hooks/use-url-params";
import { EditIcon, RotateCwIcon } from "lucide-react";

export function EditShippingButton({ step }: { step: string }) {
	const { setParams, isPending } = useUrlParams();

	return (
		<Button
			size="icon"
			variant="ghost"
			onClick={() => setParams("step", step)}
			className="flex items-center justify-center"
		>
			{isPending ? (
				<RotateCwIcon size={20} className="h-6 w-6 animate-spin" />
			) : (
				<EditIcon className="h-6 w-6" />
			)}
		</Button>
	);
}
