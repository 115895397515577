"use client";

import { useToast } from "@/components/ui/hooks/use-toast";
import { usePathname } from "next/navigation";
import {
	RadioGroup,
	RadioGroupItem,
} from "@/components/ui/components/radio-group";
import { Typography } from "@/components/ui/components/typography";
import { RotateCwIcon } from "lucide-react";
import { Label } from "@/components/ui/components/label";
import { Params } from "@/commercetools/types/next.types";
import { useTransition } from "react";
import { useRouter } from "next/navigation";
import { CheckoutSteps } from "@/tastics/checkout/types";
import { Button } from "@/components/ui/components/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
} from "@/components/ui/components/form";
import { ShippingMethodSchema, shippingMethodSchema } from "./schema";

export function SelectShippingMethod({
	params,
	shippingMethodSelected,
	shippingMethods,
	setShippingMethodAction,
	freeShippingAvailable,
}: {
	params: Params;
	shippingMethodSelected: string;
	shippingMethods: {
		shippingMethodId: string;
		description: string;
		name: string;
		price: string;
	}[];
	setShippingMethodAction: (
		shippingMethodId: string,
		params: Params
	) => Promise<void>;
	freeShippingAvailable: boolean;
}) {
	const [isPending, startTransition] = useTransition();
	const router = useRouter();
	const pathname = usePathname();
	const { toast } = useToast();

	const form = useForm<ShippingMethodSchema>({
		resolver: zodResolver(shippingMethodSchema),
		defaultValues: {
			id: shippingMethodSelected,
		},
	});

	const onSubmit = async (values: ShippingMethodSchema) => {
		try {
			await setShippingMethodAction(values.id, params);

			startTransition(() => {
				router.replace(`${pathname}?step=${CheckoutSteps.Payment}`);
			});
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to setup shipping method",
			});
		}
	};

	return (
		<Form {...form}>
			<form
				className="space-y-4 md:mx-11 md:mb-11"
				onSubmit={form.handleSubmit(onSubmit)}
			>
				<FormField
					control={form.control}
					name="id"
					render={({ field }) => (
						<FormItem className="space-y-3">
							<FormControl>
								<RadioGroup
									onValueChange={field.onChange}
									defaultValue={field.value}
									className="flex flex-col space-y-1"
									disabled={form.formState.isSubmitting || isPending}
								>
									{shippingMethods?.length > 0 ? (
										shippingMethods.map((option, index) => {
											if (!shippingMethods) return null;
											return (
												<div
													className="asio-shipping-panel__container"
													key={`${option.shippingMethodId}-${index}`}
												>
													<RadioGroupItem
														value={option.shippingMethodId}
														disabled={form.formState.isSubmitting}
													/>
													<div className="asio-shipping-panel__shipping-method">
														<Label htmlFor={`${option.shippingMethodId}`}>
															{option.name}
															{" ("}
															{option.description}
															{")"}
														</Label>
														{option.name.includes("Standard") &&
														freeShippingAvailable ? (
															<div className="flex items-center gap-2">
																<Typography.Text>FREE</Typography.Text>
																<Typography.Text className="text-gray-400 line-through">
																	{option.price}
																</Typography.Text>
															</div>
														) : (
															<Typography.Text>{option.price}</Typography.Text>
														)}
													</div>
												</div>
											);
										})
									) : (
										<RotateCwIcon size={20} className="h-6 w-6 animate-spin" />
									)}
								</RadioGroup>
							</FormControl>
						</FormItem>
					)}
				/>

				<Button
					disabled={
						form.getValues("id") === "" ||
						form.formState.isSubmitting ||
						isPending
					}
					variant="secondary"
					className="asio-checkout-save__button"
				>
					{form.formState.isSubmitting || isPending ? (
						<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
					) : (
						<span>Continue to Payment</span>
					)}
				</Button>
			</form>
		</Form>
	);
}
