"use client";

import { Button } from "@/components/ui/components/button";
import AccountNav from "../menus";
import { NewAddressForm } from "@/components/account/addresses/new-address";
import { useState } from "react";
import { DisplayAddress } from "./displayAddress";

export default function AccountAddressesTastic() {
	const [openForm, setOpenForm] = useState(false);

	return (
		<div>
			<AccountNav />
			<div className="max-w-[800px] mx-auto">
				<h1 className="text-3xl font-semibold">Addresses</h1>
				<Button
					type="submit"
					variant="primary"
					className="asio-account-addresses__button my-8"
					onClick={() => setOpenForm(!openForm)}
				>
					<span>Add new address</span>
				</Button>
				<hr className="mb-10" />

				{openForm && (
					<NewAddressForm
						data={{
							address: {
								firstName: "",
								lastName: "",
								streetNumber: "",
								streetName: "",
								city: "",
								postalCode: "",
								state: "",
								country: "",
							},
						}}
						setAccountAction={async () => {}}
					/>
				)}
				<div className="grid grid-cols-2 gap-4 mb-6">
					<DisplayAddress
						firstName="John"
						lastName="Doe"
						streetNumber="240"
						streetName="S.State Street"
						city="Westerville"
						postalCode="43081"
						state="OH"
						country="US"
					/>
					<DisplayAddress
						firstName="Jane"
						lastName="Smith"
						streetNumber="4487"
						streetName="Irving Blvd"
						city="New York"
						postalCode="87114"
						state="NY"
						country="US"
					/>
					<DisplayAddress
						firstName="Amy"
						lastName="Lee"
						streetName="Street"
						streetNumber="777"
						city="Vancouver"
						postalCode="12121"
						state="BC"
						country="Canada"
					/>
				</div>
			</div>
		</div>
	);
}
