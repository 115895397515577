"use client";

import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/components/form";
import { Input } from "@/components/ui/components/input";
import { useFormContext } from "react-hook-form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/components/select";
import { AddressesFormSchema } from "./schema";

export function ShippingAddressForm() {
	const form = useFormContext<AddressesFormSchema>();

	return (
		<div className="grid md:grid-cols-2 grid-cols-1 gap-4 font-poppins">
			<FormField
				control={form.control}
				name="shipping.firstName"
				render={({ field }) => (
					<FormItem>
						<FormLabel>First Name</FormLabel>
						<FormControl>
							<Input {...field} placeholder="First Name" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.lastName"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Last Name</FormLabel>
						<FormControl>
							<Input {...field} placeholder="Last Name" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.streetName"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Street Name</FormLabel>
						<FormControl>
							<Input {...field} placeholder="Street Name" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.streetNumber"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Street Number</FormLabel>
						<FormControl>
							<Input {...field} placeholder="Street Number" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.city"
				render={({ field }) => (
					<FormItem>
						<FormLabel>City</FormLabel>
						<FormControl>
							<Input {...field} placeholder="City" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.postalCode"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Postal Code</FormLabel>
						<FormControl>
							<Input {...field} placeholder="Postal Code" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.state"
				render={({ field }) => (
					<FormItem>
						<FormLabel>State</FormLabel>
						<FormControl>
							<Input {...field} placeholder="State" />
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
			<FormField
				control={form.control}
				name="shipping.country"
				render={({ field }) => (
					<FormItem className="w-full">
						<FormLabel>Country</FormLabel>
						<FormControl>
							<Select
								onValueChange={field.onChange}
								defaultValue={field.value || ""}
							>
								<FormControl>
									<SelectTrigger>
										<SelectValue placeholder="Select a Country" />
									</SelectTrigger>
								</FormControl>
								<SelectContent>
									<SelectItem value="US">US</SelectItem>
								</SelectContent>
							</Select>
						</FormControl>
						<FormMessage />
					</FormItem>
				)}
			/>
		</div>
	);
}
