"use client";

import Link from "next/link";
import { CartButton } from "./cart-button";
import { Image } from "@/components/ui/components/image";
import { AccountMenu } from "./account-menu";
import { sdk } from "@/commercetools/sdk";
import { useRouter } from "next/navigation";
import { CategoryNavMenu } from "@/components/navigation/category-nav-menu";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { getTotalItems } from "@/lib/cart";
import {
	cleanSesssionCartAction,
	resetCartAction,
} from "@/commercetools/actions/cart.actions";
import { SearchItemButton } from "./search-button";

type HeaderProps = {
	logo: { src: string; alt: string; href: string };
	wishlist: {
		[key: string]: string;
	};
	links: [
		{
			name: string;
			reference: {
				link: string;
				openInNewWindow: boolean;
			};
		}
	];
	items: any[];
};

export function Header({ logo, links, items }: HeaderProps) {
	const router = useRouter();

	const logoutAction = async () => {
		sdk.configureForNext("en");
		await sdk.composableCommerce.account.logout();
		router.replace("/");
	};

	const [isOpen, setIsOpen] = useState(false);

	const totalItems = getTotalItems(items ?? []);

	return (
		<>
			<header className="lg:flex justify-between items-center border-b p-4 hidden">
				<Link href={logo.href} prefetch={false} aria-label="Logo">
					<Image src={logo.src} alt={logo.alt} width={40} height={40} />
				</Link>
				<CategoryNavMenu links={links} />
				<div className="flex gap-2 items-center justify-center">
					<AccountMenu />
					<SearchItemButton />
					<CartButton itemsQty={totalItems} />
				</div>
			</header>

			{/* Mobile header */}
			<header className="flex justify-between items-center border-b p-4 lg:hidden">
				<Link href={logo.href} prefetch={false} aria-label="Logo">
					<Image src={logo.src} alt={logo.alt} width={40} height={40} />
				</Link>
				<div className="flex gap-2 items-center justify-center">
					<AccountMenu />
					<SearchItemButton />
					<CartButton itemsQty={totalItems} />
					<button onClick={() => setIsOpen(true)} className="ml-3">
						<CartIcon />
					</button>
				</div>
			</header>
			<div
				onClick={() => setIsOpen(false)}
				className={cn(
					isOpen && "fixed w-full h-screen inset-0 bg-black opacity-45 z-10"
				)}
			></div>
			<div
				className={cn(
					isOpen ? "block" : "hidden",
					"bg-white fixed h-screen z-10 w-4/5 inset-0 overflow-y-auto"
				)}
			>
				<div className="p-4">
					<div className="flex items-center justify-between h-16 mb-4">
						<Link
							href={logo.href}
							prefetch={false}
							aria-label="Logo"
							onClick={() => setIsOpen(false)}
						>
							<Image src={logo.src} alt={logo.alt} width={40} height={40} />
						</Link>
						<button onClick={() => setIsOpen(false)}>
							<CloseIcon />
						</button>
					</div>
					<div>
						{links.map((link, index) => (
							<ul
								key={`nav-item-${link.name.toLowerCase().replace(" ", "")}`}
								className="px-3 text-left md:cursor-pointer group"
							>
								<li className="py-3 flex justify-between items-center font-semibold">
									<a href={link.reference.link}>{link.name}</a>
								</li>
							</ul>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export function CartIcon() {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18 2.3457H0V0.345703H18V2.3457ZM18 10.3457H0V8.3457H18V10.3457ZM0 18.3457H18V16.3457H0V18.3457Z"
				fill="#5B6DFC"
			/>
		</svg>
	);
}

export function CloseIcon() {
	return (
		<svg
			aria-labelledby="closeIconTitle"
			color="#000"
			fill="none"
			height="36px"
			role="img"
			stroke="#000"
			strokeLinecap="square"
			strokeLinejoin="miter"
			strokeWidth="1"
			viewBox="0 0 24 24"
			width="36px"
		>
			<title id="closeIcon" />
			<path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />
		</svg>
	);
}

export function ChevronUpIcon() {
	return (
		<svg
			enableBackground="new 0 0 50 50"
			height="16px"
			viewBox="0 0 50 50"
			width="16px"
		>
			<rect fill="none" height="50" width="50" />
			<polygon points="2.75,35 4.836,37.086 25,16.922 45.164,37.086 47.25,35 25,12.75 " />
			<rect fill="none" height="50" width="50" />
		</svg>
	);
}

export function ChevronDownIcon() {
	return (
		<svg
			enableBackground="new 0 0 50 50"
			height="16px"
			viewBox="0 0 50 50"
			width="16px"
		>
			<rect fill="none" height="50" width="50" />
			<polygon points="47.25,15 45.164,12.914 25,33.078 4.836,12.914 2.75,15 25,37.25 " />
		</svg>
	);
}
