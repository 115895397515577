"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Label } from "@/components/ui/components/label";
import {
	RadioGroup,
	RadioGroupItem,
} from "@/components/ui/components/radio-group";
import { cn } from "@/lib/utils";
import { useState } from "react";

type TasticData = {
	required: boolean;
	label: string;
	options: [
		{
			name: string;
			value: string;
		}
	];
};

export default function RadioButtonsInputTastic({
	data,
}: TasticProps<TasticData>) {
	const [isError, setIsError] = useState(false);

	return (
		<div className="flex items-center relative pr-6">
			<div className="space-y-2 my-4 ml-4 mr-1 w-full">
				<Label className={cn(isError && "text-destructive")}>
					{data.label}
				</Label>
				<div className="space-y-1">
					<RadioGroup className="flex flex-col space-y-1">
						{data.options &&
							data.options.map((option, index) => (
								<div
									key={index}
									className="flex items-center space-x-2 border-bcolor border rounded-md p-4"
								>
									<RadioGroupItem value={option.value} />
									<div className="flex flex-row justify-between items-center w-full font-normal text-body font-poppins md:text-[14px] md:leading-[22px] text-[16px] leading-[26px]">
										<Label htmlFor={option.value}>{option.name}</Label>
									</div>
								</div>
							))}
					</RadioGroup>
				</div>
				{isError && (
					<p className="text-sm font-medium text-destructive">
						{"This field is required."}
					</p>
				)}
			</div>
		</div>
	);
}
