"use client";

import AccountMenus from "../menus";

export default function AccountOrdersTastic() {
	return (
		<div>
			<AccountMenus />
			<div className="max-w-[800px] mx-auto">
				<h1 className="text-3xl font-semibold">Orders</h1>
			</div>
		</div>
	);
}
