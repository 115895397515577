"use client";

import {
	EmbeddedCheckout,
	EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

import { StripeCardElementOptions, loadStripe } from "@stripe/stripe-js";
import { Typography } from "@/components/ui/components/typography";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createCheckoutSessionAction } from "@/commercetools/actions/payment.actions";

const stripeKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
const stripe = stripeKey ? loadStripe(stripeKey) : null;

export const StripePaymentCard = ({
	onCompleted,
}: {
	onCompleted: (complete: boolean) => void;
}) => {
	const useOptions: StripeCardElementOptions = useMemo(() => {
		return {
			style: {
				base: {
					fontFamily: "Inter, sans-serif",
					color: "#424270",
					"::placeholder": {
						color: "rgb(107 114 128)",
					},
				},
			},
			classes: {
				base: "pt-3 pb-1 block w-full md:w-2/4 h-11 px-4 mt-0 bg-ui-bg-field border rounded-md appearance-none focus:outline-none focus:ring-0 focus:shadow-borders-interactive-with-active border-ui-border-base hover:bg-ui-bg-field-hover transition-all duration-300 ease-in-out",
			},
		};
	}, []);

	useEffect(() => {
		// call extension
		(async () => {
			const result: any = await createCheckoutSessionAction({
				// getting the result
				locale: "en",
			});
			setSecret(result.data.client_secret);
			console.log("The result from extension: ", result);
		})();
	}, []);

	const [secret, setSecret] = useState(null);

	return (
		<div className="asio-payment-card-container">
			<h1>Starting from here</h1>
			{secret && (
				<EmbeddedCheckoutProvider
					stripe={stripe}
					options={{ clientSecret: secret }}
				>
					<EmbeddedCheckout />
				</EmbeddedCheckoutProvider>
			)}
		</div>
	);
};

/** 
 * 
 * <Typography.Text className="txt-medium-plus text-ui-fg-base mb-1">
				Enter your card detailssssss:
			</Typography.Text>

			<CardElement
				options={useOptions as StripeCardElementOptions}
				onChange={(e) => {
					onCompleted(e.complete);
				}}
			/>
 * 
 */
