import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/components/form";
import { Input } from "@/components/ui/components/input";
import { AccountFormSchema } from "./schema";
import { useFormContext } from "react-hook-form";

export function AccountForm() {
	const form = useFormContext<AccountFormSchema>();

	return (
		<div className="mb-8">
			<div className="grid md:grid-cols-2 grid-cols-1 gap-4 font-poppins mb-4">
				<FormField
					control={form.control}
					name="account.firstName"
					render={({ field }) => (
						<FormItem>
							<FormLabel>First Name</FormLabel>
							<FormControl>
								<Input {...field} placeholder="First Name" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="account.lastName"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Last Name</FormLabel>
							<FormControl>
								<Input {...field} placeholder="Last Name" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>
			<div className="grid grid-cols-1 gap-4 font-poppins md:mt-0 mt-4">
				<FormField
					control={form.control}
					name="account.email"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Email</FormLabel>
							<FormControl>
								<Input {...field} placeholder="Email" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>
		</div>
	);
}
