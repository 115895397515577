import { Button } from "@/components/ui/components/button";
import { SizeOptions } from "@/components/product/filters/filter-component";

export type SizesFilterOptionsProps = {
	name: string;
	options: SizeOptions;
	optionSelected: string;
	handleChange: (e: any) => void;
};

export function FilterButton({
	name,
	options,
	optionSelected,
	handleChange,
}: SizesFilterOptionsProps) {
	return (
		<div className="flex flex-row gap-2 flex-wrap px-1 py-1">
			{options.map((option) => (
				<Button
					variant="outline"
					key={option.key}
					value={option.key}
					onClick={handleChange}
					className={
						optionSelected === option.key
							? "asio-variant-selector__selected"
							: ""
					}
				>
					{option.label}
				</Button>
			))}
		</div>
	);
}
