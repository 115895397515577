"use client";

import { Translations } from "@/commercetools/types/i18n.types";
import { PropsWithChildren, createContext, useContext } from "react";

export interface I18nContextShape {
	translations: Translations;
}

export const I18nContext = createContext({} as I18nContextShape);

export function I18nProvider({
	translations,
	children,
}: PropsWithChildren<I18nContextShape>) {
	return (
		<I18nContext.Provider value={{ translations }}>
			{children}
		</I18nContext.Provider>
	);
}

export const useI18n = () => useContext(I18nContext);
