"use client";

import { Button } from "@/components/ui/components/button";
import { Typography } from "@/components/ui/components/typography";
import { useUrlParams } from "@/hooks/use-url-params";
import { ProductAttribute } from "../types";
import { useState } from "react";
import { cn } from "@/lib/utils";

export const ProductSizeSelector = ({
	label,
	sizes,
	initialSizeSelected,
	sizesWithStock,
}: {
	label: string;
	sizes: string[];
	initialSizeSelected: string;
	sizesWithStock: string[];
}) => {
	const { setParams } = useUrlParams();
	const [sizeSelected, setSize] = useState<string>(initialSizeSelected);

	return (
		<div className="asio-size-selector__container">
			<Typography.Heading as="h5" className="asio-size-selector__label">
				{label}
			</Typography.Heading>
			<div className="flex space-x-4">
				{(sizes || []).map((size, index) => {
					const hasStock = sizesWithStock.includes(size);
					return (
						<Button
							key={`${size}-${index}`}
							variant="outline"
							className={cn(
								hasStock
									? "asio-size-selector__option"
									: "asio-selector__option-disabled",
								sizeSelected === size && "asio-variant-selector__selected"
							)}
							onClick={() => {
								setParams(ProductAttribute.Size, size.toString());
								setSize(size);
							}}
							disabled={!hasStock}
						>
							{size}
						</Button>
					);
				})}
			</div>
		</div>
	);
};
