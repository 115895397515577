"use client";

import { Button } from "@/components/ui/components/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/components/dropdown";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { UserIcon } from "lucide-react";
import Link from "next/link";
import { sdk } from "@/commercetools/sdk";
import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";

export function AccountMenu() {
	const router = useRouter();

	const [data, setData] = useState<any>(null);
	const [isLoading, setLoading] = useState(true);

	sdk.configureForNext("en");

	/* useEffect(() => {
		sdk.composableCommerce.account.getAccount().then((data) => {
			setData(data);
			setLoading(false);
		});
	}, []); */

	/* const logoutAction = async () => {
		sdk.configureForNext("en");
		await sdk.composableCommerce.account.logout();
		router.replace("/");
	}; */

	const isLoggedIn = isLoading ? false : data?.loggedIn;

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="gap-2">
					<UserIcon size={20} />
				</Button>
			</DropdownMenuTrigger>
			{isLoggedIn && (
				<DropdownMenuContent className="bg-white w-40 p-6 flex flex-col items-start space-y-2">
					<DropdownMenuItem className="cursor-pointer">
						<Link href="/my-account/account">Account</Link>
					</DropdownMenuItem>
					<DropdownMenuItem className="cursor-pointer">
						<Link href="/my-account/my-orders">Orders</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
						className="flex cursor-pointer"
						// onClick={logoutAction}
					>
						Log out
					</DropdownMenuItem>
				</DropdownMenuContent>
			)}
			{!isLoggedIn && (
				<DropdownMenuContent className="bg-white w-40 p-6 flex flex-col items-start space-y-2">
					<DropdownMenuItem className="cursor-pointer">
						<Link href="/login">Login</Link>
					</DropdownMenuItem>
				</DropdownMenuContent>
			)}
		</DropdownMenu>
	);
}
