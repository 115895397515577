"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Image } from "@/components/ui/components/image";

type Media = {
	file: string;
	alt: string;
};

type TasticData = {
	image?: {
		media: Media;
	};
};

export default function HeroWithSingleImageTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div className="landing__hero_single_image_container">
			{data?.image && (
				<Image
					src={data?.image?.media?.file}
					alt={data?.image?.media?.alt}
					className="landing__hero_single_image"
				/>
			)}
		</div>
	);
}
