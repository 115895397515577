"use client";

import { Product } from "@/shared/types/product";
import { TasticProps } from "@/commercetools/tastics";
import { DataSource } from "@/commercetools/types/data-sources";

import "@/styles/ecommerce/products-carousel.css";
import { useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";
import { ProductCard } from "@/components/product/card";

type DataSourceProps = {
	items: Product[];
};

export default function ProductsCarouselTastic({
	data,
}: TasticProps<DataSource<DataSourceProps>>) {
	const [productWidth, setProductWidth] = useState(0);
	const [currentPosition, setCurrentPosition] = useState<number>(0);
	const carousel = useRef<HTMLInputElement>(null);
	const productCardWidth = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (productCardWidth.current != null) {
			setProductWidth(
				(productCardWidth.current && productCardWidth.current.clientWidth) || 0
			);
		}
	}, []);

	const slideLeft = () => {
		if (carousel.current != null) {
			carousel.current.scrollLeft = carousel?.current.scrollLeft - productWidth;
			setCurrentPosition(carousel?.current.scrollLeft - productWidth);
		}
	};

	const slideRight = () => {
		if (carousel.current != null) {
			carousel.current.scrollLeft = carousel?.current.scrollLeft + productWidth;
			setCurrentPosition(carousel?.current.scrollLeft + productWidth);
		}
	};

	const items = data.data?.dataSource?.items;

	const getTotalSlides = () => items?.length ?? 0;
	const getSliderTotalWidth = () => getTotalSlides() * productWidth;
	const getSliderTotalMargin = () => (getTotalSlides() - 1) * 16;

	const showRightArrow = () => {
		const offsetWidth = carousel?.current?.offsetWidth
			? carousel?.current?.offsetWidth
			: 0;
		const activeWidth = currentPosition + offsetWidth;
		return getTotalSlides() > 1 &&
			getSliderTotalWidth() + getSliderTotalMargin() >= activeWidth
			? true
			: false;
	};
	const showLeftArrow = () => {
		return currentPosition > 0 || currentPosition >= productWidth
			? true
			: false;
	};

	return (
		<>
			{items && items?.length > 4 && (
				<div className="space-x-6 text-right pr-4 mb-2">
					{showLeftArrow() && (
						<button onClick={slideLeft}>
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.82757 7.68925L9.40047 2.11636L7.98625 0.702148L0.706251 7.98215L-0.000854492 8.68925L0.706251 9.39636L7.98625 16.6764L9.40046 15.2621L3.82757 9.68925H16V7.68925H3.82757Z"
									fill="#0B0F31"
								/>
							</svg>
						</button>
					)}
					{showRightArrow() && (
						<button onClick={slideRight}>
							<svg width="16" height="17" viewBox="0 0 16 17" fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.1724 7.68925L6.59953 2.11636L8.01375 0.702148L15.2937 7.98215L16.0009 8.68925L15.2937 9.39636L8.01375 16.6764L6.59954 15.2621L12.1724 9.68925H0V7.68925H12.1724Z"
									fill="#0B0F31"
								/>
							</svg>
						</button>
					)}
				</div>
			)}

			<div
				className={cn(
					items && items?.length > 4
						? "asio-products-carousel__container"
						: "landing__hero_products_container"
				)}
				ref={carousel}
			>
				{items &&
					items.map((item, index) => (
						<div
							className={cn(
								items && items?.length > 4
									? "asio-products-carousel__product"
									: "w-full grid"
							)}
							key={`${item.productId}-${index}`}
							ref={productCardWidth}
						>
							<ProductCard item={item} />
						</div>
					))}
			</div>
		</>
	);
}
