"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Typography } from "@/components/ui/components/typography";

type TasticData = {
	eyebrow?: string;
	title?: string;
	backgroundColor?: string;
};

export default function HeroWithEyebrowTitleTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div
			className="landing__hero_eyebrow_title_container"
			style={{
				backgroundColor: data?.backgroundColor
					? `#${data.backgroundColor}`
					: "#fff",
			}}
		>
			{data?.eyebrow && (
				<Typography.Text className="landing__hero_eyebrow_title_eyebrow">
					{data.eyebrow}
				</Typography.Text>
			)}
			{data?.title && (
				<Typography.Text className="landing__hero_eyebrow_title_title">
					{data.title}
				</Typography.Text>
			)}
		</div>
	);
}
