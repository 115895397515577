"use client";

import { useState } from "react";
import { cn } from "@/lib/utils";
import { TableRow } from "@/components/ui/components/table";
import { Typography } from "@/components/ui/components/typography";
import { Image } from "@/components/ui/components/image";
import { CartItem } from "../cart-product-tile";
import { XIcon } from "lucide-react";
import { Price } from "@/components/product/detail/price";
import { Button } from "@/components/ui/components/button";
import { UpdateQuantityItemCart } from "@/components/cart/quantity-selector";

export default function Item({
	item,
	onRemoveItem,
}: {
	item: CartItem;
	onRemoveItem(id: string): void;
}) {
	const [isDeleted, setIsDeleted] = useState(false);
	const setOpacityEffect = () => {
		setIsDeleted(true);
	};

	return (
		<div className={cn(isDeleted && "asio-cart-item__container__deleted")}>
			<TableRow className="asio-cart-item__container">
				<div className="asio-cart-item__details">
					<div className="asio-cart-item__details-image-container">
						<Image
							src={
								item?.img?.src?.replace(".jpg", "-small.jpg") ||
								"/images/image-placeholder-small.png"
							}
							alt={item.name}
							className="asio-cart-item__details-image"
						/>
						<UpdateQuantityItemCart
							lineItemId={item.id}
							count={item.amount}
							className="md:hidden"
						/>
					</div>
					<div className="text-left flex flex-col space-y-6">
						<div className="flex flex-col">
							<div>
								<Typography.Heading className="asio-cart-item__title" as="h5">
									{item.name}
								</Typography.Heading>
							</div>
							{Object.keys(item.attributes).map((attribute, index) => (
								<Typography.Text
									key={`${attribute}-${index}`}
									className="text-xs font-poppins capitalize"
								>
									<Typography.Text as="span" className="font-bold mr-1">
										{attribute}:
									</Typography.Text>
									{item.attributes[attribute]}
								</Typography.Text>
							))}
						</div>
						<UpdateQuantityItemCart
							lineItemId={item.id}
							count={item.amount}
							className="hidden md:flex"
						/>
					</div>
				</div>
				<div className="flex flex-col items-end justify-between">
					<Button
						variant="ghost"
						onClick={() => {
							onRemoveItem(item.id);
							setOpacityEffect();
						}}
						className="p-0 !h-6 hover:bg-transparent"
					>
						<XIcon className="h-6 w-6 text-gray-500" />
					</Button>
					<Price className="flex flex-col mt-0">
						<Typography.Heading className="asio-product-price-discount__container">
							{item.discountedPrice !== "$0" ? item.discountedPrice : item.price}
						</Typography.Heading>
						{item.discountedPrice !== "$0" && (
							<Typography.Heading className="asio-product-price__value">
								{item.price}
							</Typography.Heading>
						)}
					</Price>
				</div>
			</TableRow>
		</div>
	);
}
