"use client";

import { Params, SearchParams } from "@/commercetools/types/next.types";
import { StripeCheckoutExpress } from "@/components/checkout/payment/_components/stripe-payment.express";

export default function CheckoutExpressTastic({
	params,
	searchParams,
	cart,
}: {
	params: Params;
	searchParams: SearchParams;
	cart: any;
}) {
	console.log(cart.data.lineItems);
	return (
		<div className="py-8">
			<StripeCheckoutExpress lineItems={cart.data.lineItems} />
		</div>
	);
}
