"use client";

import { sdk } from "@/commercetools/sdk";
import { LoginForm } from "@/components/account/login/login.form";
import {
	Card,
	CardHeader,
	CardTitle,
	CardDescription,
	CardContent,
} from "@/components/ui/components/card";
import { Divider } from "@/components/ui/components/divider";
import { LoginAccountPayload } from "@commercetools/frontend-composable-commerce/lib/types/payloads/AccountPayloads";
import { useParams, useRouter } from "next/navigation";

export default function AccountLoginTastic() {
	const { locale } = useParams();
	const { refresh } = useRouter();

	const loginAction = async (payload: LoginAccountPayload) => {
		sdk.configureForNext(locale);
		await sdk.composableCommerce.account.login(payload);
		refresh();
	};

	return (
		<div className="container">
			<Card className="mx-auto mt-20 max-w-md space-y-4 border border-bcolor rounded-xl bg-white p-6">
				<CardHeader>
					<CardTitle className="text-2xl text-body font-poppins flex flex-col items-center">
						Login
						<Divider className="mt-2 mb-4"/>
					</CardTitle>
					<CardDescription className="text-center my-0 text-body font-poppins">
						Please enter your username and password
					</CardDescription>
				</CardHeader>
				<CardContent className="!mt-0">
					<LoginForm<LoginAccountPayload> loginAction={loginAction} />
				</CardContent>
			</Card>
		</div>
	);
}
