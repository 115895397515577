"use client";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Button } from "@/components/ui/components/button";

import { Address } from "@/shared/types/account/Address";
import { PaymentIntent } from "@stripe/stripe-js";

export function StripePaymentButton({
	paymentDetails,
	billingAddress,
	onPaymentCompleted,
}: {
	billingAddress: Address;
	paymentDetails: {
		clientSecret: string;
		paymentMethodId: string;
	};
	onPaymentCompleted: (paymentIntent: PaymentIntent) => void;
}) {
	const [submitting, setSubmitting] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const stripe = useStripe();

	const disabled = !stripe || !billingAddress ? true : false;

	const clientSecret = paymentDetails.clientSecret;
	const paymentMethodId = paymentDetails.paymentMethodId;

	const handlePayment = async () => {
		setSubmitting(true);

		if (!stripe || !billingAddress) {
			setSubmitting(false);
			return;
		}

		if (clientSecret && paymentMethodId) {
			await stripe
				.confirmCardPayment(clientSecret, {
					payment_method: paymentMethodId,
				})
				.then(({ error, paymentIntent }) => {
					if (error) {
						const pi = error.payment_intent;

						if (
							(pi && pi.status === "requires_capture") ||
							(pi && pi.status === "succeeded")
						) {
							paymentIntent && onPaymentCompleted(paymentIntent);
						}

						setErrorMessage(error.message || null);
						return;
					}

					if (
						(paymentIntent && paymentIntent.status === "requires_capture") ||
						paymentIntent.status === "succeeded"
					) {
						return onPaymentCompleted(paymentIntent);
					}

					return;
				});
		}
	};

	return (
		<Button
			disabled={disabled || submitting || !clientSecret}
			variant="primary"
			className="asio-place-order__button"
			onClick={handlePayment}
		>
			<span>Place Order</span>
		</Button>
	);
}
