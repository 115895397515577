"use client";

import { TasticProps } from "@/commercetools/tastics";
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	CardDescription,
} from "@/components/ui/components/card";
import Link from "next/link";
import { Image } from "@/components/ui/components/image";

type Media = {
	file: string;
	alt: string;
};

type Product = {
	image: {
		media: Media;
	};
	name: string;
	description: string;
	reference: {
		link: string;
		openInNewWindow: boolean;
		type: string;
	};
};

type TasticData = {
	categories: Product[];
};

export default function HeroWithCategoriesTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div className="landing__hero_categories_container">
			{data.categories.map((category, index) => (
				<Card key={`${category.name}-${index}`}>
					<Link href={category.reference.link}>
						<CardContent className="landing__hero_categories_content">
							<div className="landing__hero_categories_image_container">
								<Image
									src={category.image.media.file}
									alt={category.image.media.alt}
									className="asio-item-card__content-image h-[23rem]"
								/>
							</div>
							<CardHeader className="landing__hero_categories_header">
								<CardTitle className="landing__hero_categories_title">
									{category.name}
								</CardTitle>
								<CardDescription className="landing__hero_categories_description">
									{category.description}
								</CardDescription>
							</CardHeader>
						</CardContent>
					</Link>
				</Card>
			))}
		</div>
	);
}
