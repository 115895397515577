const screenSizes = {
	smallMobile: 320,
	mobile: 480,
	tablet: 744,
	desktop: 1024,
	mediumDesktop: 1280,
	largeDesktop: 1440,
	hugeDesktop: 1666,
};

export const {
	smallMobile,
	mobile,
	tablet,
	desktop,
	mediumDesktop,
	largeDesktop,
	hugeDesktop,
} = screenSizes;
