"use client";

import { Form } from "@/components/ui/components/form";
import { useForm } from "react-hook-form";
import { AddressFormSchema, addressFormSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/components/button";
import { useToast } from "@/components/ui/hooks/use-toast";
import { Params } from "@/commercetools/types/next.types";
import { useParams } from "next/navigation";
import { RotateCwIcon } from "lucide-react";
import { useMemo, useTransition } from "react";
import { AddressForm } from "./address.form";

export function NewAddressForm<T>({
	data,
	setAccountAction,
}: {
	data: {
		address: {
			firstName: string;
			lastName: string;
			streetName: string;
			streetNumber: string;
			city: string;
			postalCode: string;
			state: string;
			country: string;
		};
	};
	setAccountAction: (payload: T, params: Params) => Promise<void>;
}) {
	const [isPending, startTransition] = useTransition();
	const { toast } = useToast();
	const { locale } = useParams();

	const defaultAddressInfo = useMemo(() => {
		return data?.address
			? {
					firstName: data?.address?.firstName ?? "",
					lastName: data?.address?.lastName ?? "",
					streetName: data?.address?.streetName ?? "",
					streetNumber: data?.address?.streetNumber ?? "",
					city: data?.address?.city ?? "",
					postalCode: data?.address?.postalCode ?? "",
					state: data?.address?.state ?? "",
					country: data?.address?.country ?? "",
			  }
			: {
					firstName: "",
					lastName: "",
					streetName: "",
					streetNumber: "",
					city: "",
					postalCode: "",
					state: "",
					country: "",
			  };
	}, [data]);

	const form = useForm<AddressFormSchema>({
		resolver: zodResolver(addressFormSchema),
		defaultValues: {
			address: defaultAddressInfo,
		},
	});

	const onSubmit = async (values: AddressFormSchema) => {
		try {
			const payload = {
				address: {
					firstName: values.address.firstName,
					lastName: values.address.lastName,
					streetName: values.address.streetName,
					streetNumber: values.address.streetNumber,
					city: values.address.city,
					postalCode: values.address.postalCode,
					state: values.address.state,
					country: values.address.country,
				},
			} as T;
			startTransition(async () => {
				await setAccountAction(payload, { locale });
			});
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to update addresses",
			});
		}
	};

	return (
		<Form {...form}>
			<form
				className="space-y-4 mt-8 mb-20"
				onSubmit={(e) => {
					form.handleSubmit(onSubmit)(e);
				}}
			>
				<AddressForm />
				<div className="flex items-center justify-center">
					<Button
						type="submit"
						variant="primary"
						className="asio-account-addresses__button my-8"
						disabled={isPending}
					>
						{isPending ? (
							<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
						) : (
							<span>Submit</span>
						)}
					</Button>
				</div>
			</form>
		</Form>
	);
}
