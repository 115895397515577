"use client";

import { useState, useTransition } from "react";
import { Button } from "@/components/ui/components/button";
import { cn } from "@/lib/utils";
import { updateItemInCartAction } from "@/commercetools/actions/cart.actions";
import { useParams } from "next/navigation";
import { RotateCwIcon } from "lucide-react";

type UpdateQuantityItemCartProps = {
	lineItemId: string;
	count?: number;
	className?: string;
	amount?: number;
};

export const UpdateQuantityItemCart = ({
	lineItemId,
	count = 1,
	className,
}: UpdateQuantityItemCartProps) => {
	const params = useParams();
	const [quantity, setQuantity] = useState(count);
	const [isPending, startTransition] = useTransition();

	return (
		<form
		action={async () => {
			try {
				await updateItemInCartAction(
					{
						lineItem: {
							id: lineItemId,
							count: quantity,
						},
					},
					params
				);

				startTransition(async () => {
				});
			} catch {
				console.log(`An error ocurred`);
			}
		}}
		>
			<div
				className={cn("asio-update-quantity-item-cart__container", className)}
			>
				<Button
					type="submit"
					variant="outline"
					className="asio-remove-quantity-item-cart"
					disabled={isPending}
					onClick={() => setQuantity(quantity > 2 ? quantity - 1 : 1)}
				>
					-
				</Button>

				{isPending ? (<RotateCwIcon size={20} className="h-4 w-4 animate-spin text-slate-500" />) : (<span className="asio-update-cart-item__value">{count}</span>)}

				<Button
					className="asio-add-quantity-item-cart"
					type="submit"
					variant="outline"
					disabled={isPending}
					onClick={() => setQuantity((prev) => prev + 1)}
				>
					+
				</Button>
			</div>
		</form>
	);
};
