import { Button } from "@/components/ui/components/button";
import {
	DropdownMenu,
	DropdownMenuTrigger,
} from "@/components/ui/components/dropdown";
import { PLPTitle } from "@/components/product/list/plp-title";
import { PropsWithChildren } from "react";
import { ArrowUpDownIcon, ChevronRight } from "lucide-react";
import { SearchParams } from "@/commercetools/types/next.types";
import { Breadcrumbs } from "@/components/ui/components/breadcrumb";

export function PLPHeader({
	triggerText,
	children,
	count,
	title,
	sortEnabled = true,
	searchParams,
}: PropsWithChildren<{
	triggerText?: string;
	sortEnabled?: boolean;
	searchParams?: SearchParams;
	count: number;
	title?: string;
}>) {
	return (
		<div className="flex flex-col items-start">
			<div className="flex items-center flex-col">
				<Breadcrumbs
					homeElement={"Home"}
					separator={<ChevronRight size={14} />}
					activeClasses="text-[#B7002A] font-poppins font-bold text-[12px] uppercase"
					containerClasses="flex flex-row items-center h-[40px] mb-4"
					listClasses="hover:underline md:mx-1 mx-0.3 text-[12px] font-poppins uppercase"
				/>
			</div>
			<PLPTitle count={count} title={title} searchParams={searchParams} />
			{sortEnabled && (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button className="ml-auto shrink-0" variant="outline">
							<ArrowUpDownIcon />
							{triggerText}
						</Button>
					</DropdownMenuTrigger>
					{children}
				</DropdownMenu>
			)}
		</div>
	);
}
