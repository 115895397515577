import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@/components/ui/components/accordion";
import { PropsWithChildren } from "react";

export function CustomAccordionItem({
	value,
	triggerText,
	children,
}: PropsWithChildren<{
	value: string;
	triggerText: string;
}>) {
	return (
		<AccordionItem value={value}>
			<AccordionTrigger className="text-base">{triggerText}</AccordionTrigger>
			<AccordionContent>{children}</AccordionContent>
		</AccordionItem>
	);
}
