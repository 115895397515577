import { Image } from "@/components/ui/components/image";

type FooterLogoProps = React.HTMLAttributes<HTMLImageElement> & {
	src: string;
	width?: number;
	height?: number;
	alt: string;
};

export function FooterLogo({
	src,
	width,
	height,
	alt,
	className,
}: FooterLogoProps) {
	return (
		<Image
			src={src}
			width={width}
			height={height}
			alt={alt}
			className={className}
		/>
	);
}
