import { FooterAddress } from "./footer-address";
import { FooterPanel } from "./footer-panel";
import { FooterEmail } from "./footer-email";
import { FooterLink } from "./footer-link";
import { FooterLogo } from "./footer-logo";
import { cn } from "@/lib/utils";

type FooterProps = React.HTMLAttributes<HTMLDivElement>;


function Footer({ children, className }: FooterProps) {
  return <footer className={cn(className, "asio-footer")}>{children}</footer>;
}

Footer.Panel = FooterPanel;
Footer.Address = FooterAddress;
Footer.Email = FooterEmail;
Footer.Logo = FooterLogo;
Footer.Link = FooterLink;

export { Footer }