"use client";

import { cn } from "@/lib/utils";
import { useState } from "react";
import { Search, X } from "lucide-react";
import { Button } from "@/components/ui/components/button";
import { Input } from "@/components/ui/components/input";
import { useRouter } from "next/navigation";

export function SearchItemButton() {
	const [clickSearch, setClickSearch] = useState(false);
	const [textToSearch, setTextToSearch] = useState("");

	const router = useRouter();

	const search = () => {
		router.push(`/search?description=${textToSearch}`);
		setClickSearch(false);
	};
	return (
		<>
			<Button
				variant="ghost"
				className="gap-2"
				onClick={() => setClickSearch(!clickSearch)}
			>
				<Search size={20} />
			</Button>
			<div
				className={cn(
					clickSearch
						? "absolute top-full z-10 bg-white w-full left-0 h-16 flex justify-center items-center gap-6 py-2 px-6"
						: "hidden"
				)}
			>
				<Input
					type="text"
					placeholder="Search products"
					value={textToSearch}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							search();
						}
					}}
					onChange={(e) => setTextToSearch(e.target.value)}
					className="h-auto border-t-0 border-x-0 rounded-none w-2/3"
				/>
				<button onClick={() => search()}>
					<Search size={20} />
				</button>
				<button onClick={() => setClickSearch(false)}>
					<X size={20} />
				</button>
			</div>
		</>
	);
}
