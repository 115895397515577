"use client";
import { TasticProps } from "@/commercetools/tastics";
import { DataSource } from "@/commercetools/types/data-sources";
import { PageProps } from "@/commercetools/types/next.types";
import { ProductCard } from "@/components/product/card";
import { PLPHeader } from "@/components/product/list/plp-header";
import { Product } from "@/shared/types/product";
import { Facet } from "@/shared/types/result";

type DataSourceProps = {
	items: Product[];
	category: string;
	facets: Facet[];
	previousCursor?: string;
	nextCursor?: string;
	total: number;
	totalItems: number;
};

const EmptySearchResults = ({ searchParams }: any) => {
	return (
		<div className="flex flex-col items-center py-10 px-3 space-y-6">
			<h1 className="text-center text-4xl font-bold">
				{searchParams === undefined || searchParams == ""
					? `We’re sorry, no results found.`
					: `We’re sorry, no results for “${searchParams}”`}
			</h1>
			<div className="w-3/5">
				<p className="text-center">
					Check the spelling or try a more general term.
					<p className="text-center">
						Need more help? Contact us at{" "}
						<a
							href="mailto:info@ariessolutions.io"
							aria-label="Email us"
							className="hover:underline"
						>
							info@ariessolutions.io
						</a>
						.
					</p>
				</p>
			</div>
		</div>
	);
};

export default function ProductsListSearchTastic({
	data,
	searchParams,
}: TasticProps<DataSource<DataSourceProps & PageProps>>) {
	console.log("data", data);
	let items = data?.data?.dataSource?.items || [];

	if (items.length === 0)
		return <EmptySearchResults searchParams={searchParams.description} />;

	return (
		<div className="flex flex-col p-4 container">
			<PLPHeader sortEnabled={false} count={20} searchParams={searchParams} />
			<div className="grid grid-cols-2 md:grid-cols-4 gap-4 pt-4">
				{items.map((item, index) => (
					<ProductCard key={`${item.productId}-${index}`} item={item} />
				))}
			</div>
		</div>
	);
}
