"use client";

import { TasticProps } from "@/commercetools/tastics";
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	CardPrice,
} from "@/components/ui/components/card";
import Link from "next/link";
import { Image } from "@/components/ui/components/image";
import { Money } from "@/lib/currency";

type Media = {
	file: string;
	alt: string;
};

type Product = {
	image: {
		media: Media;
	};
	name: string;
	price: Money;
	reference: {
		link: string;
		openInNewWindow: boolean;
		type: string;
	};
};

type TasticData = {
	products: Product[];
};

export default function HeroWithProductsTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div className="landing__hero_products_container">
			{data.products.map((product, index) => (
				<Card key={`${product.name}-${index}`}>
					<Link href={product.reference.link}>
						<CardContent className="landing__hero_products_content">
							<div className="landing__hero_products_image_container">
								<Image
									src={product.image.media.file}
									alt={product.image.media.alt}
									className="asio-item-card__content-image"
								/>
							</div>
							<CardHeader className="landing__hero_products_header">
								<CardTitle className="landing__hero_products_title">
									{product.name}
								</CardTitle>
								<CardPrice
									price={product.price}
									className="landing__hero_products_price"
								/>
							</CardHeader>
						</CardContent>
					</Link>
				</Card>
			))}
		</div>
	);
}
