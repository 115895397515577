"use client";

import { useParams } from "next/navigation";
import { Translations } from "../../types/i18n.types";
import { sdk } from "../../sdk";
import { Toaster } from "@/components/ui/components/toaster";
import { PropsWithChildren } from "react";
import { I18nProvider } from "./i18n/provider";

export type TranslationOptions = {
	values?: Record<string, string | number>;
	defaultMessage?: string;
};

export function Providers({
	translations,
	children,
}: PropsWithChildren<{
	translations: Translations;
}>) {
	const { locale } = useParams();
	sdk.configureForNext(locale);

	return (
		<I18nProvider translations={translations}>
			{children}
			<Toaster />
		</I18nProvider>
	);
}
