import { cn } from "@/lib/utils";
import Link from "next/link";

type FooterLinkProps = React.HTMLAttributes<HTMLDivElement> & {
	href: string;
	openInNewWindow: boolean;
};

export function FooterLink({
	children,
	className,
	href,
	openInNewWindow,
}: FooterLinkProps) {
	return (
		<Link
			href={href}
			aria-label="Footer link"
			className={cn(className, "asio-footer-link")}
			target={openInNewWindow ? "_blank" : "_self"}
			rel="noopener noreferrer"
		>
			{children}
		</Link>
	);
}
