"use client";

import { ReactNode } from "react";
import { SubmitButton } from "@/components/ui/components/submit-button";
import { removeDiscountCodeAction } from "@/commercetools/actions/cart.actions";
import { useParams } from "next/navigation";
import { toast } from "@/components/ui/hooks/use-toast";
import { revalidatePath } from "next/cache";

type RemoveDiscountCodeProps = {
	discountId?: string;
	icon?: ReactNode;
	label?: string;
};

export const RemoveDiscountCode = ({
	discountId,
	icon,
	label,
}: RemoveDiscountCodeProps) => {
	const params = useParams();

	const onSubmit = async (discountId: string) => {
		try {
			await removeDiscountCodeAction(discountId, params);
		} catch (error) {
			toast({
				variant: "destructive",
				title: "Invalid discount code",
				description: "An error occurred while trying to apply the promo code",
			});
		}
		await revalidatePath("/cart");
	};

	return (
		<form
			action={async (formData: FormData) => {
				await onSubmit((formData.get("discountId") as string) || "");
			}}
		>
			<div className="asio-discount-code__container">
				<input
					id="discountId"
					name="discountId"
					defaultValue={discountId}
					hidden
				/>
				<SubmitButton className="asio-remove-discount-code__button">
					{icon ?? label}
				</SubmitButton>
			</div>
		</form>
	);
};
