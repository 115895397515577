"use client";

import { SearchParams } from "@/commercetools/types/next.types";
import { Typography } from "@/components/ui/components/typography";
import { Payment } from "@/shared/types/cart";
import { CreditCardIcon } from "lucide-react";

export function PaymentDetails({
	cardDigits,
}: {
	cardDigits: string;
	payment: Payment | undefined;
	searchParams: SearchParams;
}) {
	if (!cardDigits) {
		return;
	}

	return (
		<div className="flex gap-2 md:ml-6 ml-3">
			<CreditCardIcon size={24} />
			<Typography.Text>Credit Card ending in</Typography.Text>
			<Typography.Text weight="bold">{cardDigits}</Typography.Text>
		</div>
	);
}
