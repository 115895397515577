"use client";

import AccountNav from "../menus";
import { useFormContext } from "react-hook-form";
import { AccountChangePasswordForm } from "@/components/account/change-password/change-password";
import { PasswordFormSchema } from "@/components/account/change-password/schema";

export default function AccountChangePasswordTastic() {
	const form = useFormContext<PasswordFormSchema>();

	return (
		<div>
			<AccountNav />
			<div className="md:max-w-[800px] md:mx-auto mx-5">
				<h1 className="text-3xl font-semibold">Change Password</h1>
				<AccountChangePasswordForm
					data={{
						password: {
							currentPassword: "",
							newPassword: "",
							confirmPassword: "",
						},
					}}
					setPasswordAction={async () => {}}
				/>
			</div>
		</div>
	);
}
