"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Input } from "@/components/ui/components/input";
import { useId } from "react";

type TasticData = {
	name: string;
	value: string;
};

export default function HiddenInputTastic({ data }: TasticProps<TasticData>) {
	return (
		<Input type="hidden" id={useId()} name={data.name} value={data.value} />
	);
}
