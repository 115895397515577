import { SearchParams } from "@/commercetools/types/next.types";
import { Typography } from "@/components/ui/components/typography";
import { capitalizeFirstLetter } from "@/lib/utils";

export function PLPTitle({
	count,
	title,
	searchParams,
}: {
	count?: number;
	title?: string;
	searchParams?: SearchParams;
}) {
	return (
		<Typography.Heading
			as="h2"
			size="xl"
			weight="bold"
			className="md:mx-1 mx-0.3"
		>
			Related products
		</Typography.Heading>
	);
}
