"use client";

import Link from "next/link";
import { Typography } from "../ui/components/typography";
import { Order } from "@/shared/types/cart";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	CreditCardIcon,
	TagIcon,
} from "lucide-react";
import { CurrencyHelper, Money } from "@/lib/currency";
import { formatDate } from "@/lib/utils";
import { CartProductTile } from "@/components/cart/templates/cart-product-tile";
import { parseLineItems } from "@/lib/cart";
import { Divider } from "../ui/components/divider";
import { useEffect } from "react";
import { sdk } from "@/commercetools/sdk";

export default function ThankYouDetail({
	order,
	locale,
}: {
	order: Order;
	locale: string;
	getCartFunction?: () => void;
}) {
	const items = parseLineItems(order?.lineItems ?? []);

	useEffect(() => {
		(async () => {
			sdk.configureForNext(locale);
			await sdk.composableCommerce.cart.getCart();
		})();
	}, []);

	const cleanPrice = (price: any) => {
		if (!price || !price.totalPrice) return 0;
		return price.totalPrice.centAmount;
	};

	const subtotal: Money = {
		fractionDigits: 2,
		centAmount: order?.lineItems?.reduce(
			(acc, item) => acc + cleanPrice(item),
			0
		),
		currencyCode: "USD",
	};

	const totalShipping =
		(subtotal?.centAmount ?? 0) >= 10000
			? 0
			: order?.shippingInfo?.price?.centAmount;

	const totalDiscountsAmount = order?.discountCodes?.reduce(
		(acc, discount) => acc + (discount?.discountedAmount?.centAmount ?? 0),
		0
	);

	const total: Money = {
		fractionDigits: 2,
		centAmount:
			(subtotal.centAmount ?? 0) +
			totalShipping +
			(order?.taxed?.amount?.centAmount ?? 0) -
			(totalDiscountsAmount ?? 0),
		currencyCode: "USD",
	};

	return (
		<div className="container flex flex-row justify-center">
			<div className="asio-message-box__container">
				<div className="asio-message-box__header">
					<Typography.Heading as="h1" size="6xl">
						Thank you!
					</Typography.Heading>
					<Typography.Heading as="h2" size="2xl">
						Your order was placed successfully.
					</Typography.Heading>
				</div>
				<div className="asio-message-box__body">
					<div className="asio-message-box__content-order-details">
						<Typography.Text as="p">
							We have sent the order confirmation details to {order.email}
						</Typography.Text>
						{order?.createdAt && (
							<Typography.Text as="p">
								Order date: {formatDate(order?.createdAt)}
							</Typography.Text>
						)}
						<Typography.Text as="p" className="text-primarybtn font-semibold">
							Order number: {order.orderId}
						</Typography.Text>
					</div>
					<div className="asio-message-box__content-summary">
						<div className="asio-message-box__content-title">
							<Typography.Heading as="h3" className="asio-step-title-text">
								Summary
							</Typography.Heading>
							<Divider />
						</div>
						{items?.map((item, index) => (
							<CartProductTile item={item} key={index} />
						))}
						<div className="asio-message-box__content-summary-details">
							<div>
								<Typography.Text as="p">Subtotal</Typography.Text>
								<Typography.Text as="p">
									{CurrencyHelper.formatMoneyCurrency(subtotal, locale)}
								</Typography.Text>
							</div>
							<div>
								<Typography.Text as="p">Shipping</Typography.Text>
								<Typography.Text as="p">
									{(subtotal?.centAmount ?? 0) >= 10000
										? "FREE"
										: CurrencyHelper.formatMoneyCurrency(
												order?.shippingInfo.price,
												locale
										  )}
								</Typography.Text>
							</div>
							<div>
								<Typography.Text as="p">Taxes</Typography.Text>
								{order?.taxed?.amount && (
									<Typography.Text as="p">
										{CurrencyHelper.formatMoneyCurrency(
											order.taxed.amount,
											locale
										)}
									</Typography.Text>
								)}
							</div>
							<div className="flex !items-start !flex-col gap-2">
								<Typography.Text as="span">Discounts</Typography.Text>
								{order?.discountCodes?.map((discount, idx) => (
									<div
										className="flex justify-between items-center text-body w-full pl-4"
										key={idx}
									>
										<Typography.Text
											as="span"
											className="font-semibold flex flex-row items-center gap-2"
										>
											<TagIcon className="h-4 w-4 stroke-[2]" />
											{discount.name}
										</Typography.Text>
										<Typography.Text as="span">
											{"- "}
											{CurrencyHelper.formatMoneyCurrency(
												discount.discountedAmount as Money
											)}
										</Typography.Text>
									</div>
								))}
							</div>
						</div>
						<div className="total-order__text">
							<Typography.Text as="p">Total</Typography.Text>
							<Typography.Text as="p">
								{CurrencyHelper.formatMoneyCurrency(total, locale)}
							</Typography.Text>
						</div>
					</div>
					<div className="asio-message-box__content-delivery">
						<div className="asio-message-box__content-title">
							<Typography.Heading as="h3" className="asio-step-title-text">
								Delivery
							</Typography.Heading>
							<Divider />
						</div>
						<div className="asio-message-box__content-delivery-container">
							<div>
								<Typography.Heading as="h4" size="lg" className="details-title">
									Shipping Address
								</Typography.Heading>
								<Typography.Text>
									{order?.shippingAddress?.firstName}{" "}
									{order?.shippingAddress?.lastName}
								</Typography.Text>
								<Typography.Text>
									{order?.shippingAddress?.streetName}{" "}
									{order?.shippingAddress?.streetNumber}
								</Typography.Text>
								<Typography.Text>
									{order?.shippingAddress?.postalCode},{" "}
									{order?.shippingAddress?.city}
								</Typography.Text>
								<Typography.Text>
									{order?.shippingAddress?.country?.toUpperCase()}
								</Typography.Text>
							</div>
							<div>
								<Typography.Heading as="h4" size="lg" className="details-title">
									Shipping Method
								</Typography.Heading>
								<Typography.Text as="p">
									{order?.shippingInfo?.name}
								</Typography.Text>
							</div>
						</div>
					</div>
					<div className="asio-message-box__content-payment">
						<div className="asio-message-box__content-title">
							<Typography.Heading as="h3" className="asio-step-title-text">
								Payment
							</Typography.Heading>
							<Divider />
						</div>
						<div className="asio-message-box__content-payment-container">
							<div>
								<Typography.Heading as="h4" size="lg" className="details-title">
									Billing Address
								</Typography.Heading>
								<Typography.Text>
									{order?.billingAddress?.firstName}{" "}
									{order?.billingAddress?.lastName}
								</Typography.Text>
								<Typography.Text>
									{order?.billingAddress?.streetName}{" "}
									{order?.billingAddress?.streetNumber}
								</Typography.Text>
								<Typography.Text>
									{order?.billingAddress?.postalCode},{" "}
									{order?.billingAddress?.city}
								</Typography.Text>
								<Typography.Text>
									{order?.billingAddress?.country?.toUpperCase()}
								</Typography.Text>
							</div>
							<div>
								<Typography.Heading as="h4" size="lg" className="details-title">
									Payment Method
								</Typography.Heading>
								<div className="flex flex-row gap-2">
									<CreditCardIcon size={24} />
									<Typography.Text as="p">
										Credit Card ending in 4242
									</Typography.Text>
								</div>
							</div>
						</div>
					</div>
					<div className="asio-message-box__content-footer">
						<div className="asio-message-box__content-footer-links">
							<Link href="/faqs">FAQs</Link>
							<Link
								href="https://www.ariessolutions.io/contact-aries/"
								target="_blank"
							>
								Contact Us
							</Link>
							<Link href="/returns-exchanges">Returns & Exchanges</Link>
						</div>
						<div className="asio-message-box__content-footer-btns__container">
							<Link href="/" className="asio-message-box__content-footer-btns">
								<ArrowLeftIcon size={18} />
								Home
							</Link>
							<Link href="/" className="asio-message-box__content-footer-btns">
								Continue Shopping
								<ArrowRightIcon size={18} />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
