"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Checkbox } from "@/components/ui/components/checkbox";
import { Label } from "@/components/ui/components/label";
import { cn } from "@/lib/utils";
import { useState } from "react";

type TasticData = {
	required: boolean;
	label: string;
	options: [
		{
			selected?: boolean;
			name: string;
			value: string;
		}
	];
};

export default function CheckboxInputTastic({ data }: TasticProps<TasticData>) {
	const [isError, setIsError] = useState(false);

	return (
		<div className="flex items-center relative pr-6">
			<div className="space-y-2 my-4 ml-4 mr-1 w-full">
				<Label className={cn(isError && "text-destructive")}>
					{data.label}
				</Label>
				{data.options &&
					data.options.map((option, index) => (
						<div
							key={index}
							className="flex space-x-2 items-center border-bcolor border rounded-md p-4"
						>
							<Checkbox id={option.value} />
							<div className="space-y-2 leading-none">
								<Label
									htmlFor={option.value}
									className="font-normal text-body-foreground font-poppins md:text-[14px] md:leading-[22px] text-[16px] leading-[26px] peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
								>
									{option.name}
								</Label>
							</div>
						</div>
					))}
				{isError && (
					<p className="text-sm font-medium text-destructive">
						{"This field is required."}
					</p>
				)}
			</div>
		</div>
	);
}
