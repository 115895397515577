"use client";
import { resetCartAction } from "@/commercetools/actions/cart.actions";
import { TasticProps } from "@/commercetools/tastics";
import { Header } from "@/components/structure/header";

export default function StructureHeaderTastic({
	data,
	params,
	cart,
}: TasticProps<{
	logo: {
		media: {
			file: string;
			alt: string;
		};
	};
	logoLink: {
		pageFolder: {
			_url: string;
		};
	};
	links: [
		{
			name: string;
			reference: {
				link: string;
				openInNewWindow: boolean;
			};
		}
	];
}>) {
	const totalItems =
		cart.data?.lineItems?.reduce(
			(acc: any, curr: any) => acc + (curr.count as number),
			0
		) ?? 0;

	return (
		<>
			<Header
				logo={{
					src: data.logo.media.file,
					alt: data.logo.media.alt,
					href: data.logoLink.pageFolder._url,
				}}
				wishlist={{}}
				links={data.links}
				items={cart.data?.lineItems}
			/>
		</>
	);
}
