"use client";

import { useProductDetails } from "@/commercetools/hooks/use-product-details";
import { TasticProps } from "@/commercetools/tastics";
import { DataSource } from "@/commercetools/types/data-sources";
import { Gallery } from "@/components/product/detail/gallery";
import usePreloadImages from "@/hooks/use-preload-images";
import { Product } from "@/shared/types/product";

type SearchParams = { [key: string]: string };

export default function ProductGalleryTastic({
	data,
	searchParams,
}: TasticProps<DataSource<{ product: Product }>> & {
	searchParams: SearchParams;
}) {
	const _product = data?.data?.dataSource?.product;
	const { images } = useProductDetails(
		_product || ({} as Product),
		searchParams
	);

	usePreloadImages(images.map((image) => image ?? []).flat());

	return <Gallery images={images} />;
}
