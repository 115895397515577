"use client";

import { Accordion } from "@/components/ui/components/accordion";
import React, { useEffect, useState } from "react";
import { useSearchParams, useRouter } from "next/navigation";
import { Typography } from "@/components/ui/components/typography";
import { CustomAccordionItem } from "@/components/product/filters/accordian-item";
import { FilterCheckbox } from "@/components/product/filters/checkbox";
import { FilterButton } from "./button";

export type FilterOption = {
	value: string;
	component: JSX.Element;
};

export type FilterOptions = {
	[key: string]: FilterOption[];
};

export type SizeOption = {
	key: string;
	label: string;
	selected: boolean;
};

export type SizeOptions = SizeOption[];

export function FilterComponent({
	filterOptions,
	sizeFilterOptions,
}: {
	filterOptions: FilterOptions;
	sizeFilterOptions: SizeOptions;
}) {
	const [hydrated, setHydrated] = useState(false);
	useEffect(() => {
		setHydrated(true);
	}, []);

	const router = useRouter();
	const searchParams = useSearchParams();

	let queryParams = new URLSearchParams(searchParams);

	const handleClick = (checkbox: HTMLInputElement) => {
		const checkboxes = document.getElementsByName(
			checkbox.name
		) as NodeListOf<HTMLInputElement>;

		checkboxes.forEach((item) => {
			if (item !== checkbox) item.checked = false;
		});

		if (checkbox.checked === false) {
			queryParams?.delete(checkbox.name);
		} else {
			if (queryParams?.has(checkbox.name)) {
				queryParams.set(checkbox.name, checkbox.value);
			} else {
				queryParams?.append(checkbox.name, checkbox.value);
			}
		}

		const path = window.location.pathname + "?" + queryParams?.toString();
		router.push(path);
	};

	const handleSizeClick = (button: HTMLButtonElement) => {
		sizeFilterOptions = sizeFilterOptions.map((option) => {
			if (option.label === button.value) {
				if (
					queryParams?.has("size") &&
					queryParams?.get("size") === button.value
				) {
					queryParams?.delete("size");
					return { ...option, selected: false };
				} else {
					queryParams?.set("size", button.value);
					return { ...option, selected: true };
				}
			} else {
				return { ...option, selected: false };
			}
		});

		const path = window.location.pathname + "?" + queryParams?.toString();
		router.push(path);
	};

	const checkHandler = (checkBoxType: string, checkBoxValue: string) => {
		if (typeof window !== "undefined") {
			queryParams = new URLSearchParams(searchParams);

			const value = queryParams.get(checkBoxType);
			return checkBoxValue === value;
		}
		return false;
	};

	if (!hydrated) {
		return null;
	}

	return (
		<aside className="flex flex-col items-start gap-4 w-full">
			<Typography.Heading as="h3" className="text-sm font-semibold">
				Filter by
			</Typography.Heading>
			<Accordion
				className="w-full"
				type="multiple"
				defaultValue={Object.keys(filterOptions)}
			>
				{Object.entries(filterOptions).map(([filterType, options]) => (
					<CustomAccordionItem
						key={filterType}
						value={filterType}
						triggerText={
							filterType.charAt(0).toUpperCase() + filterType.slice(1)
						}
					>
						{filterType !== "sizes" && (
							<FilterCheckbox
								name={filterType}
								options={options}
								defaultValues={options
									.filter((option) => checkHandler(filterType, option.value))
									.map((option) => option.value)}
								handleChange={(e) => handleClick(e.target)}
							/>
						)}
					</CustomAccordionItem>
				))}
			</Accordion>
			<Accordion
				className="w-full"
				type="multiple"
				defaultValue={Object.keys(sizeFilterOptions)}
			>
				<CustomAccordionItem value="sizes" triggerText="Sizes">
					<FilterButton
						name="sizes"
						optionSelected={searchParams.get("size") || ""}
						options={sizeFilterOptions}
						handleChange={(e) => handleSizeClick(e.target)}
					/>
				</CustomAccordionItem>
			</Accordion>
		</aside>
	);
}
