import { nonEmptyString } from "@/lib/utils";
import * as z from "zod";

export const addressSchema = z.object({
	firstName: nonEmptyString,
	lastName: nonEmptyString,
	streetName: nonEmptyString,
	streetNumber: nonEmptyString,
	city: nonEmptyString,
	postalCode: nonEmptyString,
	state: nonEmptyString,
	country: nonEmptyString,
});

export const addressFormSchema = z.object({
	address: addressSchema,
});

export type AddressSchema = z.infer<typeof addressSchema>;
export type AddressFormSchema = z.infer<typeof addressFormSchema>;
