"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Input } from "@/components/ui/components/input";
import { Label } from "@/components/ui/components/label";
import { cn } from "@/lib/utils";
import { Info } from "lucide-react";
import { useState } from "react";
import "../../../styles/form.css";

type TasticData = {
	required: boolean;
	inputType: string;
	label: string;
	placeholder?: string;
	inputHint?: string;
	validationRules?: string;
	validationErrorText?: string;
};

export default function SingleLineTextInputTastic({
	data,
}: TasticProps<TasticData>) {
	const [inputValue, setInputValue] = useState("");
	const [validationError, setValidationError] = useState("");
	const [isError, setIsError] = useState(false);
	const [showInputHint, setShowInputHint] = useState(false);

	const htmlForString = data.label
		.replace(/\s+/g, "")
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
			index === 0 ? letter.toLowerCase() : letter.toUpperCase()
		)
		.replace(/\s+/g, "");
	const regexString = data.validationRules ? data.validationRules : "";
	const regexObject = new RegExp(regexString);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setInputValue(value);
		if (!data.validationRules) return;
		if (!regexObject.test(value)) {
			setValidationError(
				data.validationErrorText ? data.validationErrorText : ""
			);
		} else {
			setValidationError("");
		}
	};

	return (
		<div className="flex items-center relative pr-6">
			<div className="space-y-2 my-4 ml-4 mr-1 w-full">
				<Label
					htmlFor={htmlForString}
					className={cn((isError || validationError) && "text-destructive")}
				>
					{data.label}
				</Label>
				<div className="flex relative">
					<Input
						required={data.required}
						type={data.inputType}
						value={inputValue}
						placeholder={data.placeholder}
						onChange={handleChange}
						className={cn(data.inputType === "date" && "inline-block")}
					/>
					{data.inputHint && (
						<div className="flex justify-end items-center absolute w-full h-full right-4 space-x-1.5">
							{showInputHint && (
								<div className="max-w-[200px]">
									<p className="bg-black text-white p-2 rounded-md z-10 opacity-90 pointer-events-none text-sm">
										{data.inputHint}
									</p>
								</div>
							)}

							<button className="self-center w-6">
								<Info onClick={() => setShowInputHint(!showInputHint)} />
							</button>
						</div>
					)}
				</div>
				{(isError || validationError) && (
					<p className="text-sm font-medium text-destructive">
						{isError ? "This field is required." : validationError}
					</p>
				)}
			</div>
		</div>
	);
}
