"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Typography } from "@/components/ui/components/typography";
import { Input } from "@/components/ui/components/input";
import { Button } from "@/components/ui/components/button";

type TasticData = {
	title?: string;
	description?: string;
};

export default function NewsletterFormTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div className="landing__newsletter_form_container">
			<div className="flex-1">
				{data?.title && (
					<Typography.Text className="landing__newsletter_form_title">
						{data.title}
					</Typography.Text>
				)}
			</div>
			<div className="landing__newsletter_form_content">
				<form className="flex">
					<Input
						type="email"
						placeholder="Enter email address"
						className="rounded-r-none"
					/>
					<Button type="submit" className="rounded-l-none h-auto">
						Sign Up
					</Button>
				</form>
				{data?.description && (
					<Typography.Text className="landing__newsletter_form_description">
						{data.description}
					</Typography.Text>
				)}
			</div>
		</div>
	);
}
