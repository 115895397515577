'use client';

import React, { ReactNode } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

function replaceDashesWithSpaces(inputString: string): string {
	const words = inputString.split('-');

	const capitalizedWords = words.map((word) => {
		if (word.length === 0) {
			return '';
		}
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});

	const resultString = capitalizedWords.join(' ');

	return resultString;
}

function toLowerCaseWithDashes(inputString: string): string {
	// Split the input string into words
	const words = inputString.split(' ');

	// Lowercase and join the words with dashes
	const resultString = words.map((word) => word.toLowerCase()).join('-');

	return resultString;
}

const getPaths = (pathNames: string[]) => {
	if (pathNames.includes('category')) {
		return pathNames.slice(1, pathNames.length);
	} else if (pathNames.includes('catalog')) {
		return pathNames.slice(0, -1);
	} else {
		return pathNames;
	}
};

type BreadCrumbProps = {
	homeElement: ReactNode;
	separator: ReactNode;
	containerClasses?: string;
	listClasses?: string;
	activeClasses?: string;
};

export const Breadcrumbs = ({
	homeElement,
	separator,
	containerClasses,
	listClasses,
	activeClasses,
}: BreadCrumbProps) => {
	const pathname = usePathname();
	const pathNames = pathname.split('/').filter((path) => path);

	if (pathNames.length === 0) return <></>;

	let paths: string[] = [];
	const lastItem = pathNames[pathNames.length - 1];

	if (lastItem.split('?').length > 1) {
		// remove query params
		const params = lastItem ? lastItem.split('?') : [];
		const newArr = [...pathNames.slice(0, 1), params[0]];
		paths = getPaths(newArr);
	} else {
		paths = getPaths(pathNames);
	}

	const productPath = pathNames.includes('p')
		? pathNames.slice(1, pathNames.length)
		: pathNames;
		
	const getHref = (path: string, isProduct: boolean) => {
		if (isProduct) {
			return `${pathNames.includes('p') ? '/p' : ''}/${path}`;
		} else {
			return `${
				pathNames.includes('category') ? `/category` : ''
			}/${path}`;
		}
	};

	const isProduct = pathNames.includes('p');

	return (
		<div>
			<ul className={containerClasses}>
				<li className={listClasses}>
					<Link href={`/`}>{homeElement}</Link>
				</li>
				&nbsp;{paths.length > 0 && separator}&nbsp;
				{paths
					.map((link, index) => {
						const path = isProduct
							? productPath[0]
							: paths.slice(0, index + 1).join('/');
						const href = getHref(path, isProduct);

						const itemClasses =
							paths.length === index + 1
								? `${listClasses} ${activeClasses}`
								: listClasses;
						const itemLink = replaceDashesWithSpaces(link);

						return (
							<React.Fragment key={index}>
								<li className={itemClasses}>
										<Link href={href}>{itemLink}</Link>
								</li>
								&nbsp;{paths.length !== index + 1 && separator}&nbsp;
							</React.Fragment>
						);
					})}
			</ul>
		</div>
	);
};
