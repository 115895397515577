"use client";

import { Loader2Icon } from "lucide-react";
import { useFormStatus } from "react-dom";
import {
	Button,
	ButtonProps,
	buttonVariants,
} from "@/components/ui/components/button";
import { cn } from "@/lib/utils";

export function SubmitButton(props: ButtonProps & { headless?: boolean }) {
	const { pending } = useFormStatus();
	const { isLoading } = props;

	return (
		<Button
			className={cn(
				props.headless ? "" : buttonVariants({ variant: props.variant }),
				props.className
			)}
			type="submit"
			disabled={pending || isLoading}
			aria-disabled={pending || isLoading}
			{...props}
		>
			<div className="submit-btn-label">
				{props.children}
				{pending ||
					(isLoading && (
						<Loader2Icon className="absolute h-4 w-4 animate-spin" />
					))}
			</div>
		</Button>
	);
}
