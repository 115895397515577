import { nonEmptyString } from "@/lib/utils";
import * as z from "zod";

export const addressSchema = z.object({
	firstName: nonEmptyString,
	lastName: nonEmptyString,
	streetName: nonEmptyString,
	streetNumber: nonEmptyString,
	city: nonEmptyString,
	postalCode: nonEmptyString,
	state: nonEmptyString,
	country: nonEmptyString,
});

export const addressesFormSchema = z.object({
	billing: addressSchema,
	shipping: addressSchema,
	sameAddress: z.boolean(),
});

export type AddressSchema = z.infer<typeof addressSchema>;
export type AddressesFormSchema = z.infer<typeof addressesFormSchema>;
