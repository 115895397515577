"use client";

import { useParams } from "next/navigation";
import { toast } from "@/components/ui/hooks/use-toast";
import { SubmitButton } from "@/components/ui/components/submit-button";
import { applyDiscountCodeAction } from "@/commercetools/actions/cart.actions";
import { Input } from "@/components/ui/components/input";
import { revalidatePath } from "next/cache";

export const ApplyDiscountCode = ({ label }: { label: string }) => {
	const params = useParams();

	const onSubmit = async (code: string) => {
		try {
			await applyDiscountCodeAction(code, params);
		} catch (error) {
			toast({
				variant: "destructive",
				title: "Invalid discount Code",
				description: "An error occurred while trying to apply the promo code",
			});
		}
		await revalidatePath("/cart");
	};

	return (
		<form
			action={async (formData: FormData) => {
				await onSubmit((formData.get("code") as string) || "");
			}}
		>
			<div className="asio-discount-code__container">
				<Input id="code" name="code" />
				<SubmitButton variant="secondary" className="asio-checkout-save__button h-[50px]">
					<span>{label}</span>
				</SubmitButton>
			</div>
		</form>
	);
};
