"use client";
import { useProductDetails } from "@/commercetools/hooks/use-product-details";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardPrice,
	CardTitle,
} from "@/components/ui/components/card";
import { extractAttributesValues } from "@/lib/variants";
import { Product } from "@/shared/types/product";
import Link from "next/link";
import { useMemo } from "react";
import { Image } from "@/components/ui/components/image";
import { ProductAttribute } from "../types";
import { formatText } from "@/lib/utils";
import { Divider } from "@/components/ui/components/divider";

export function ProductCard({ item }: { item: Product }) {
	const { product } = useProductDetails(item || ({} as Product));

	const colors = useMemo(
		() =>
			(product &&
				extractAttributesValues<string>(product, ProductAttribute.Color)) ||
			[],
		[product]
	);

	const hasStock = item?.variants?.some((variant) => variant.isOnStock);

	return (
		<Card>
			<Link href={item._url!} className="h-full">
				<CardContent
					className={
						hasStock
							? "flex flex-col justify-between h-full"
							: "flex flex-col justify-between grayscale"
					}
				>
					<CardHeader className="flex flex-col items-center">
						<div className="overflow-hidden rounded-md">
							<Image
								src={
									item?.variants
										.find((variant) => variant.id === item?.variants[0].id)
										?.images![0]?.replace(".jpg", "-medium.jpg") ??
									"/images/image-placeholder-medium.png"
								}
								alt={`${item.name} image`}
								className="bg-slate-100 asio-item-card__content-image"
							/>
						</div>
						<CardTitle
							className={hasStock ? "text-body mt-3" : "mt-3 text-gray-500"}
						>
							{formatText(item?.name)}
						</CardTitle>
					</CardHeader>
					<div
						className={
							hasStock
								? "flex flex-col items-center"
								: "flex flex-col items-center opacity-60"
						}
					>
						<CardPrice
							price={
								item?.variants[0]?.discountedPrice || item?.variants[0]?.price
							}
							hasStock={hasStock}
							className="text-body"
						/>
						<Divider className="justify-center" />
						<CardFooter className="mt-4">
							<div className="w-full flex flex-row justify-center space-x-3">
								{(colors || []).map((color, index) => (
									<div
										key={`${color}-${index}`}
										className="border-black-600 rounded-full border-2 w-8 h-8"
										style={{
											backgroundColor: `${color}`,
										}}
									/>
								))}
							</div>
						</CardFooter>
					</div>
				</CardContent>
			</Link>
		</Card>
	);
}
