import { LineItem } from "@/shared/types/cart/LineItem";
import { CurrencyHelper } from "./currency";
import { CartItem } from "@/components/cart/templates/cart-product-tile";
import { replaceAttributeKeysOnVariant } from "./variants";

/**
 * Parse lineItems to cart items
 * @param lineItems LineItem[]
 * @returns CartItem[]
 */
export function parseLineItems(lineItems: LineItem[]): CartItem[] {
	const lineItemsUpdated = lineItems.map((item: LineItem) => {
		if (item.variant) {
			return {
				...item,
				variant: {
					...item.variant,
					...replaceAttributeKeysOnVariant(item.variant),
				},
			};
		}
		return item;
	});

	const items = lineItemsUpdated.map((item: LineItem) => ({
		id: item.lineItemId || "",
		amount: item.count || 0,
		name: item.name || "",
		attributes: item.variant?.attributes || {},
		price:
			(item.variant &&
				CurrencyHelper.formatMoneyCurrency(item.variant.price || {})) ||
			"",
		discountedPrice:
			(item.variant &&
				CurrencyHelper.formatMoneyCurrency(
					item.variant.discountedPrice || {}
				)) ||
			"",
		img: {
			src: item.variant?.images![0],
			alt: `${item.name} image`,
		},
		isOnStock: item.variant?.isOnStock,
	}));

	return items;
}

/**
 * Get total items from lineItems
 * @param lineItems LineItem[]
 * @returns
 */
export function getTotalItems(lineItems: LineItem[]): number {
	const totalItems =
		lineItems?.reduce((acc, curr) => acc + (curr.count as number), 0) ?? 0;

	return totalItems;
}
