import { cn } from "@/lib/utils";

type FooterEmailProps = React.HTMLAttributes<HTMLDivElement>;

export function FooterEmail({ children, className }: FooterEmailProps) {
	return (
		<a
			href={`mailto:${children}`}
			aria-label="Email us"
			className={cn(className, "asio-footer-email")}
		>
			{children}
		</a>
	);
}
