import { Address } from "@/shared/types/account/Address";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";

export const compareAddresses = (address1: Address, address2: Address) => {
	return isEqual(
		omit(address1, [
			"isDefaultBillingAddress",
			"isDefaultShippingAddress",
			"isShippingAddress",
			"isBillingAddress",
		]),
		omit(address2, [
			"isDefaultBillingAddress",
			"isDefaultShippingAddress",
			"isShippingAddress",
			"isBillingAddress",
		])
	);
};
