"use client";

import { useState } from "react";
import { Params } from "@/commercetools/types/next.types";
import { RotateCwIcon } from "lucide-react";
import { Button } from "@/components/ui/components/button";
import { useToast } from "@/components/ui/hooks/use-toast";
import { useCartDrawerContext } from "@/app/providers";
import { revalidateCache } from "@/lib/revalidate-and-go.action";
import { addItemToCartAction } from "@/commercetools/actions/cart.actions";

export function AddToCartButton({
	sku,
	params,
	disabled,
}: {
	sku: string;
	params: Params;
	disabled?: boolean;
}) {
	const [, { onToggle, setIsAddingNewItem }] = useCartDrawerContext();
	const { toast } = useToast();
	const [isPending, setIsPending] = useState(false);
	const [quantity, setQuantity] = useState(1);

	const handleClick = async () => {
		try {
			setIsAddingNewItem(true);
			setIsPending(true);
			await addItemToCartAction(
				{
					variant: {
						sku,
						count: quantity,
					},
				},
				params
			);

			revalidateCache({
				tag: "cart",
				path: "/",
			});

			onToggle();
		} catch (error) {
			toast({
				variant: "destructive",
				title: "Error adding the item",
			});
		} finally {
			setIsPending(false);
		}
	};

	return (
		<Button
			variant="primary"
			className="asio-update-cart-submit__button"
			disabled={disabled || isPending}
			onClick={handleClick}
		>
			{disabled ? (
				"Out of Stock"
			) : isPending ? (
				<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
			) : (
				"Add to Cart"
			)}
		</Button>
	);
}
