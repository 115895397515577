"use client";

import { TasticProps } from "@/commercetools/tastics";
import { SubmitButton } from "@/components/ui/components/submit-button";

type TasticData = {
	label: string;
	position?: string;
};

export default function SubmitButtonTastic({ data }: TasticProps<TasticData>) {
	return (
		<div className={`flex justify-${data.position}`}>
			<SubmitButton variant="primary" className="m-4">
				<span>{data.label}</span>
			</SubmitButton>
		</div>
	);
}
