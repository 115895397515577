"use client";

import React from "react";
import { cn } from "@/lib/utils";
import {
	NavigationMenuLink,
	NavigationMenu,
	NavigationMenuList,
	NavigationMenuItem,
	NavigationMenuTrigger,
	NavigationMenuContent,
} from "@/components/ui/components/navigation-menu";

const navigation = {
	categories: [
		{
			id: "hats",
			name: "Hats",
			href: "/hats"
		},
		{
			id: "accessories",
			name: "Accessories",
			href: "/accessories"
		},
		{
			id: "home-living",
			name: "Home & Living",
			href: "/home-living",
			sections: [
				{
					name: "Pet products",
					href: "/home-living/pet-products",
				},
				{
					name: "Stationery",
					href: "/home-living/stationery",
					items: [
						{
							id: "stickers",
							name: "Stickers",
							href: "/home-living/stationery/stickers",
						},
					],
				},
				{
					name: "Drinkware & Coasters",
					href: "/home-living/drinkware-coasters",
					items: [
						{
							id: "mugs",
							name: "Mugs",
							href: "/home-living/drinkware-coasters/mugs",
						},
						{
							id: "glassware",
							name: "Glassware",
							href: "/home-living/drinkware-coasters/glassware",
						},
						{
							id: "water-bottles",
							name: "Water Bottles",
							href: "/home-living/drinkware-coasters/water-bottles",
						},
					],
				},
				{
					name: "Home Decor",
					href: "/home-living/home-decor",
					items: [
						{
							id: "candles",
							name: "Candles",
							href: "/home-living/home-decor/candles",
						},
						{
							id: "blankets",
							name: "Blankets",
							href: "/home-living/home-decor/blankets",
						},
					],
				},
				{
					name: "Wall Art",
					href: "/home-living/wall-art",
					items: [
						{
							id: "framed-posters",
							name: "Framed Posters",
							href: "/home-living/wall-art/framed-posters",
						},
					],
				},
			],
		}
	],
};

const ListItem = React.forwardRef<
	React.ElementRef<"a">,
	React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn(
						"block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
						className
					)}
					{...props}
				>
					<div className="text-sm font-medium leading-none">{title}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
						{children}
					</p>
				</a>
			</NavigationMenuLink>
		</li>
	);
});
ListItem.displayName = "ListItem";

type CategoryNavMenuProps = {
	links: [{
		name: string,
		reference: {
			link: string,
			openInNewWindow: boolean
		}
	}];
};

export function CategoryNavMenu({ links }: CategoryNavMenuProps) {
	return (
		<div className="flex flex-1 ml-6">
			<NavigationMenu className="justify-start">
				<NavigationMenuList>
					{links.map((link, index) => (
						<div key={`nav-item-${link.name.toLowerCase().replace(' ','')}`}>
								<NavigationMenuItem>
									<NavigationMenuLink
										href={link.reference.link}
										className="rounded-none text-gray-700 font-medium"
									>
										{link.name}
									</NavigationMenuLink>
								</NavigationMenuItem>
						</div>
					))}
				</NavigationMenuList>
			</NavigationMenu>
		</div>
	);
}
