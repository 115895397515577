"use client";

import useMediaQuery from "@/commercetools/helpers/hooks/useMediaQuery";
import * as screensizes from "@/commercetools/helpers/utils/screensizes";
import { TasticProps } from "@/commercetools/tastics";
import { useCallback } from "react";

type TasticData = {
	customDesktop: number;
	customMobile: number;
};

export default function SpacerTastic({ data }: TasticProps<TasticData>) {
	const [isDesktop] = useMediaQuery(screensizes.desktop);

	const getSpacing = useCallback(() => {
		if (isDesktop) return data.customDesktop;
		return data.customMobile;
	}, [isDesktop, data.customDesktop, data.customMobile]);

	return <div style={{ height: `${getSpacing() || 0}px` }}></div>;
}
