"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Typography } from "@/components/ui/components/typography";
import Link from "next/link";

type Media = {
	file: string;
	alt: string;
};

type TasticData = {
	eyebrow?: string;
	title?: string;
	description?: string;
	callToAction: [
		{
			name: string;
			reference: {
				link: string;
				openInNewWindow: boolean;
				type: string;
			};
		}
	];
	background?: {
		media: Media;
	};
};

export default function HeroWithVideoBackgroundTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<>
			<div>
				<video
					className="min-h-full w-full min-w-full object-cover h-[550px] md:h-[650px]"
					src={data?.background?.media?.file}
					autoPlay
					muted
					loop
					disablePictureInPicture
					disableRemotePlayback
					controlsList="nodownload nofullscreen noplaybackrate"
					playsInline
				></video>
			</div>
			<div className="flex flex-col justify-center text-white font-poppins px-14 md:pl-52 md:w-[65%] font-semibold text-center md:text-left absolute top-0 h-[550px] md:h-[650px]">
				{data?.eyebrow && (
					<Typography.Text className="landing__hero_main_eyebrow">
						{data?.eyebrow}
					</Typography.Text>
				)}
				{data?.title && (
					<Typography.Heading as="h1" className="landing__hero_main_title">
						{data?.title}
					</Typography.Heading>
				)}
				{data?.description && (
					<Typography.Text className="landing__hero_main_description">
						{data?.description}
					</Typography.Text>
				)}
				{data?.callToAction[0]?.name && (
					<Link
						href={
							data?.callToAction[0]?.reference?.link
								? data?.callToAction[0]?.reference?.link
								: "#"
						}
						className="asio-hero-main__button lg:w-fit"
						target={
							data?.callToAction[0]?.reference?.openInNewWindow
								? "_blank"
								: "_self"
						}
					>
						{data?.callToAction[0]?.name}
					</Link>
				)}
			</div>
		</>
	);
}
