"use client";

import { Typography } from "@/components/ui/components/typography";
import { Address } from "@/shared/types/account/Address";
import { Params } from "@/commercetools/types/next.types";

import { toast } from "@/components/ui/hooks/use-toast";
import { revalidateAndGo } from "@/lib/revalidate-and-go.action";
import {
	createOrderAction,
	resetCartAction,
} from "@/commercetools/actions/cart.actions";
import { useState } from "react";
import { StripePaymentButton } from "../payment/_components/stripe-payment-button";
import { Order, Payment } from "@/shared/types/cart";
import { refundPaymentAction } from "@/commercetools/actions/payment.actions";

export function Review({
	params,
	billingAddress,
	email,
	payment,
}: {
	params: Params;
	billingAddress: Address;
	email: string;
	payment: Payment;
}) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const placeOrder = async () => {
		try {
			setIsSubmitting(true);
			const orderCreated = (await createOrderAction(params)) as {
				isError: boolean;
				data: Order;
			};

			if (orderCreated.data.orderId) {
				await resetCartAction(params);
			}

			await revalidateAndGo(`/thank-you?order-id=${orderCreated.data.orderId}`);
		} catch (error) {
			if (payment.payment_details) {
				await refundPaymentAction(
					params,
					payment.payment_details.payment_intent_id as string
				);
			}
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to create an order",
			});
		}
		setIsSubmitting(false);
	};

	return (
		<>
			<div className="asio-review-container">
				<div className="w-full">
					<Typography.Text>
						By clicking the Place Order button, you confirm that you have read,
						understand and accept our Terms of Use, Terms of Sale and Returns
						Policy.
					</Typography.Text>
				</div>

				{payment.payment_details && (
					<StripePaymentButton
						billingAddress={billingAddress}
						paymentDetails={{
							paymentMethodId: payment.payment_details
								.payment_method_id as string,
							clientSecret: payment.payment_details.client_secret as string,
						}}
						onPaymentCompleted={placeOrder}
					/>
				)}
			</div>
		</>
	);
}
