import * as z from "zod";

export const passwordSchema = z
	.object({
		currentPassword: z.string().min(1, "Current password is required"),
		newPassword: z.string().min(1, "New password is required"),
		confirmPassword: z.string().min(1, "Confirm password is required"),
	})
	.refine((data) => data.newPassword === data.confirmPassword, {
		message: "Confirm password must match new password",
		path: ["confirmPassword"],
	});

export const passwordFormSchema = z.object({
	password: passwordSchema,
});

export type PasswordSchema = z.infer<typeof passwordSchema>;
export type PasswordFormSchema = z.infer<typeof passwordFormSchema>;
