import { Form } from "@/components/ui/components/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/components/button";
import { useToast } from "@/components/ui/hooks/use-toast";
import { Params } from "@/commercetools/types/next.types";
import { useParams } from "next/navigation";
import { useMemo, useTransition } from "react";
import { useForm } from "react-hook-form";
import { RotateCwIcon } from "lucide-react";
import { PasswordForm } from "./password.form";
import { passwordFormSchema, PasswordFormSchema } from "./schema";

export function AccountChangePasswordForm<T>({
	data,
	setPasswordAction,
}: {
	data: {
		password: {
			currentPassword: string;
			newPassword: string;
			confirmPassword: string;
		};
	};
	setPasswordAction: (payload: T, params: Params) => Promise<void>;
}) {
	const [isPending, startTransition] = useTransition();
	const { toast } = useToast();
	const { locale } = useParams();

	const defaultPasswordInfo = useMemo(() => {
		return data?.password
			? {
					currentPassword: data.password.currentPassword ?? "",
					newPassword: data.password.newPassword ?? "",
					confirmPassword: data.password.confirmPassword ?? "",
			  }
			: {
					currentPassword: "",
					newPassword: "",
					confirmPassword: "",
			  };
	}, [data]);

	const form = useForm<PasswordFormSchema>({
		resolver: zodResolver(passwordFormSchema),
		defaultValues: {
			password: defaultPasswordInfo,
		},
	});

	const onSubmit = async (values: PasswordFormSchema) => {
		try {
			const payload = {
				password: {
					currentPassword: values.password.currentPassword,
					newPassword: values.password.newPassword,
					confirmPassword: values.password.confirmPassword,
				},
			} as T;
			startTransition(async () => {
				await setPasswordAction(payload, { locale });
			});
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to update password",
			});
		}
	};

	return (
		<Form {...form}>
			<form
				className="space-y-4 mt-8 mb-20"
				onSubmit={(e) => {
					form.handleSubmit(onSubmit)(e);
				}}
			>
				<PasswordForm />
				<div className="flex items-center justify-center">
					<Button
						type="submit"
						variant="primary"
						className="asio-account-addresses__button my-8"
						disabled={isPending}
					>
						{isPending ? (
							<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
						) : (
							<span>Submit</span>
						)}
					</Button>
				</div>
			</form>
		</Form>
	);
}
