"use client";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { CreatePaymentMethodData } from "@stripe/stripe-js";
import { Address } from "@/shared/types/account";
import { Button } from "@/components/ui/components/button";
import { StripePaymentCard } from "./stripe-payment.card";
import { Payment, PaymentStatuses } from "@/shared/types/cart";
import { Money } from "@/shared/types/product";
import { addPaymentAction } from "@/commercetools/actions/payment.actions";
import { Params } from "@/commercetools/types/next.types";
import { usePathname, useRouter } from "next/navigation";
import { CheckoutSteps } from "@/tastics/checkout/types";
import { toast } from "@/components/ui/hooks/use-toast";
import { useState } from "react";
import { RotateCwIcon } from "lucide-react";
import { Typography } from "@/components/ui/components/typography";

export const AddPaymentMethod = ({
	params,
	total,
	paymentDetails,
	customerEmail,
	billingAddress,
	disabled,
}: {
	params: Params;
	total: Money;
	paymentDetails: {
		paymentIntentId: string;
		clientSecret: string;
	};
	customerEmail: string;
	billingAddress: Address;
	disabled: boolean;
}) => {
	const router = useRouter();
	const stripe = useStripe();
	const elements = useElements();
	const pathname = usePathname();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [disablePaymentButton, setDisablePaymentButton] = useState(true);

	const addPayment = async () => {
		const cardElement = elements?.getElement("card");

		if (!stripe || !elements || !cardElement) {
			return;
		}

		if (stripe) {
			try {
				const result = await stripe.createPaymentMethod({
					card: cardElement,
					type: "card",
					billing_details: {
						name: billingAddress?.firstName + " " + billingAddress?.lastName,
						address: {
							city: billingAddress?.city ?? undefined,
							country: billingAddress?.country ?? undefined,
							line1: billingAddress?.streetName ?? undefined,
							line2: billingAddress?.streetNumber ?? undefined,
							postal_code: billingAddress?.postalCode ?? undefined,
							state: billingAddress?.state ?? undefined,
						},
						email: customerEmail,
						phone: billingAddress?.phone ?? undefined,
					},
				} as CreatePaymentMethodData);

				const addPaymentPayload = {
					id: "1",
					paymentId: "1",
					paymentMethod: "card",
					paymentProvider: "stripe",
					cardSummary: "Card Summary",
					amountPlanned: {
						centAmount: total?.centAmount,
						currencyCode: total?.currencyCode,
					},
					paymentStatus: "pending" as PaymentStatuses,
					payment_details: {
						client_secret: paymentDetails.clientSecret,
						payment_intent_id: paymentDetails.paymentIntentId,
						payment_method_id: result.paymentMethod?.id,
						card_last_four_digits: result.paymentMethod?.card?.last4,
					},
				} as Payment;

				await addPaymentAction(params, addPaymentPayload);
				setIsSubmitting(true);
				router.replace(`${pathname}?step=${CheckoutSteps.Review}`);
			} catch (error) {
				toast({
					variant: "destructive",
					title: "An error ocurred",
					description: "An error occurred while trying to add a payment",
				});
			}
		}
	};

	const handleOnCompletedCard = (isCompleted: boolean) => {
		setDisablePaymentButton(!isCompleted);
	};

	return (
		<div className="asio-payment-container">
			<div className="asio-payment-suggestion">
				<div className="asio-payment-suggestion__data-content">
					<div className="asio-payment-suggestion__data-item">
						<Typography.Text weight="bold">Card number:</Typography.Text>
						<Typography.Text>4242 4242 4242 4242</Typography.Text>
					</div>
					<div className="asio-payment-suggestion__data-item">
						<Typography.Text weight="bold">Expiration date:</Typography.Text>
						<Typography.Text>Any future date</Typography.Text>
					</div>
					<div className="asio-payment-suggestion__data-item">
						<Typography.Text weight="bold">CVC:</Typography.Text>
						<Typography.Text>Any 3 digits</Typography.Text>
					</div>
					<div className="asio-payment-suggestion__data-item">
						<Typography.Text weight="bold">Postal Code:</Typography.Text>
						<Typography.Text>Any 5 digits</Typography.Text>
					</div>
				</div>
			</div>
			<StripePaymentCard onCompleted={handleOnCompletedCard} />
			<Button
				disabled={disabled || disablePaymentButton || isSubmitting}
				type="submit"
				variant="secondary"
				className="asio-checkout-save__button"
				onClick={addPayment}
			>
				{isSubmitting ? (
					<RotateCwIcon size={20} className="h-4 w-4 animate-spin" />
				) : (
					<span>Add Payment</span>
				)}
			</Button>
		</div>
	);
};
