"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Typography } from "@/components/ui/components/typography";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

type TasticData = {
	title: string;
	description: string;
};

export default function PageWithTitleMarkdownTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<article className="p-12 md:p-20 space-y-4">
			<Typography.Text className="font-bold text-2xl md:text-3xl mb-8">
				{data.title}
			</Typography.Text>
			<ReactMarkdown rehypePlugins={[rehypeRaw]}>
				{data.description}
			</ReactMarkdown>
		</article>
	);
}
