"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Footer } from "@/components/structure/footer";
import { Image } from "@/components/ui/components/image";
import { Typography } from "@/components/ui/components/typography";

type Logo = {
	media: {
		file: string;
		alt: string;
	};
};

type SocialMedia = {
	logo: Logo;
	reference: {
		link: string;
		openInNewWindow: boolean;
	};
};

type Link = {
	name: string;
	reference: {
		link: string;
		openInNewWindow: boolean;
	};
};

type TasticData = {
	logo: Logo;
	socialMedia: SocialMedia[];
	headerCol1: string;
	linksCol1: Link[];
	headerCol2: string;
	linksCol2: Link[];
	headerCol3: string;
	linksCol3: Link[];
	headerCol4: string;
	linksCol4: Link[];
};

export default function StructureFooterTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<Footer className="bg-preheading text-white">
			<div className="container mx-auto py-6 md:py-12 grid grid-cols-1 md:grid-cols-5 gap-8">
				<Footer.Panel className="text-[16px] leading-[26px] md:text-[14px] md:leading-[22px]">
					<Footer.Logo
						src={data?.logo?.media?.file}
						alt={data?.logo?.media?.alt}
						width={120}
						height={76}
					/>
					<Footer.Address className="not-italic mt-4">
						240 S. State Street
					</Footer.Address>
					<Footer.Address className="not-italic">
						Westerville, OH 43081
					</Footer.Address>
					<Footer.Email>info@ariessolutions.io</Footer.Email>
					<div className="flex space-x-2 mt-6">
						{data?.socialMedia?.map((social, index) => (
							<Footer.Link
								key={index}
								href={social.reference.link}
								openInNewWindow={social.reference.openInNewWindow}
							>
								<Image
									src={social?.logo?.media?.file}
									alt={social?.logo?.media?.alt}
									width={18}
									height={18}
								/>
							</Footer.Link>
						))}
					</div>
				</Footer.Panel>
				<Footer.Panel>
					<h6>{data?.headerCol1}</h6>
					<div className="none md:block md:w-[30px] md:h-[2px] bg-white md:mt-1 md:mb-4"></div>
					{data?.linksCol1?.map((link, index) => (
						<Footer.Link
							key={index}
							href={link.reference.link}
							openInNewWindow={link.reference.openInNewWindow}
						>
							{link.name}
						</Footer.Link>
					))}
				</Footer.Panel>
				<Footer.Panel>
					<h6>{data?.headerCol2}</h6>
					<div className="none md:block md:w-[30px] md:h-[2px] bg-white md:mt-1 md:mb-4"></div>
					{data?.linksCol2?.map((link, index) => (
						<Footer.Link
							key={index}
							href={link.reference.link}
							openInNewWindow={link.reference.openInNewWindow}
						>
							{link.name}
						</Footer.Link>
					))}
				</Footer.Panel>
				<Footer.Panel>
					<h6>{data?.headerCol3}</h6>
					<div className="none md:block md:w-[30px] md:h-[2px] bg-white md:mt-1 md:mb-4"></div>
					{data?.linksCol3?.map((link, index) => (
						<Footer.Link
							key={index}
							href={link.reference.link}
							openInNewWindow={link.reference.openInNewWindow}
						>
							{link.name}
						</Footer.Link>
					))}
				</Footer.Panel>
				<Footer.Panel>
					<h6>{data?.headerCol4}</h6>
					<div className="none md:block md:w-[30px] md:h-[2px] bg-white md:mt-1 md:mb-4"></div>
					{data?.linksCol4?.map((link, index) => (
						<Footer.Link
							key={index}
							href={link.reference.link}
							openInNewWindow={link.reference.openInNewWindow}
						>
							{link.name}
						</Footer.Link>
					))}
				</Footer.Panel>
			</div>
			<div>
				<Typography.Text className="text-sm text-center pt-8 pb-12">
					© 2023-2024{" "}
					<a
						href="https://www.ariessolutions.io/"
						target="_blank"
						className="hover:underline"
					>
						Aries Solutions
					</a>
					, All rights reserved |{" "}
					<a
						href="https://www.ariessolutions.io/composable-commerce/mach-booster-commercetools-accelerator/"
						target="_blank"
						className="hover:underline"
					>
						MACH Booster
					</a>{" "}
					commercetools demo.
				</Typography.Text>
			</div>
		</Footer>
	);
}
