"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Label } from "@/components/ui/components/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/components/select";
import { cn } from "@/lib/utils";
import { useState } from "react";

type TasticData = {
	required: boolean;
	label: string;
	options: [
		{
			selected?: boolean;
			name: string;
			value: string;
		}
	];
};

export default function DropdownInputTastic({ data }: TasticProps<TasticData>) {
	const [isError, setIsError] = useState(false);
	const selectedOption = data.options.map((option) => {
		if (option.selected) return option.name;
	});

	return (
		<div className="flex items-center relative pr-6">
			<div className="space-y-2 my-4 ml-4 mr-1 w-full">
				<Label className={cn(isError && "text-destructive")}>
					{data.label}
				</Label>
				<div className="flex space-x-1">
					<Select>
						<SelectTrigger>
							<SelectValue placeholder={selectedOption} />
						</SelectTrigger>
						<SelectContent>
							{data.options &&
								data.options.map((option, index) => (
									<SelectItem key={index} value={option.value}>
										{option.name}
									</SelectItem>
								))}
						</SelectContent>
					</Select>
				</div>
				{isError && (
					<p className="text-sm font-medium text-destructive">
						{"This field is required."}
					</p>
				)}
			</div>
		</div>
	);
}
