"use client";

import { TasticProps } from "@/commercetools/tastics";
import AccountNav from "../menus";
import { useFormContext } from "react-hook-form";
import { AccountFormSchema } from "@/components/account/my-account/schema";
import { AccountDetailsForm } from "@/components/account/my-account/account-detail";

export default function AccountDetailsTastic({ data }: TasticProps) {
	const form = useFormContext<AccountFormSchema>();

	console.log("account form", form);

	return (
		<div>
			<AccountNav />
			<div className="md:max-w-[800px] md:mx-auto mx-5">
				<h1 className="text-3xl font-semibold">Edit Account Details</h1>
				<AccountDetailsForm
					data={{
						account: {
							firstName: "John",
							lastName: "Doe",
							email: "john.doe@mail.com",
						},
						changePassword: false,
					}}
					setAccountAction={async () => {}}
				/>
			</div>
		</div>
	);
}
