"use client";

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/components/input";
import { useToast } from "@/components/ui/hooks/use-toast";
import type { LoginForm } from "./schema";
import { loginFormSchema } from "./schema";
import { revalidateAndGo } from "@/lib/revalidate-and-go.action";
import { SubmitButton } from "@/components/ui/components/submit-button";
import { useSearchParams } from "next/navigation";

export function LoginForm<T>({
	loginAction,
}: {
	loginAction: (payload: T) => Promise<void>;
}) {
	const { toast } = useToast();
	const searchParams = useSearchParams();

	const form = useForm<LoginForm>({
		resolver: zodResolver(loginFormSchema),
		defaultValues: {
			username: "" /* ignacio.giagante@ariessolutions.io */,
			password: "" /* Testing44$ */,
			remember: false,
		},
	});

	const queryParams = new URLSearchParams(searchParams);

	const callBackUrl = queryParams?.get("callbackUrl") || "";

	const onSubmit = async (values: LoginForm) => {
		try {
			await loginAction({
				email: values.username,
				password: values.password,
				remember: values.remember,
			} as T);

			await revalidateAndGo(`/${callBackUrl}`);
		} catch (error) {
			toast({
				variant: "destructive",
				title: "An error ocurred",
				description: "An error occurred while trying to log in",
			});
		}
	};

	return (
		<Form {...form}>
			<form className="w-full space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
				<FormField
					control={form.control}
					name="username"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Username</FormLabel>
							<FormControl>
								<Input placeholder="john@company.com" {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Password</FormLabel>
							<FormControl>
								<Input
									type="password"
									placeholder="sup3r_p4ssw0rd$$"
									{...field}
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<SubmitButton
					variant="primary"
					isLoading={form.formState.isSubmitting}
					className="!w-full !mt-4"
				>
					<span>Login</span>
				</SubmitButton>
			</form>
		</Form>
	);
}
