"use client";

import { useCartDrawerContext } from "@/app/providers";
import { Button } from "@/components/ui/components/button";
import { Typography } from "@/components/ui/components/typography";
import { ShoppingCartIcon } from "lucide-react";

interface CartButtonProps {
	itemsQty: number;
}
export function CartButton({ itemsQty }: CartButtonProps) {
	const [, { onToggle }] = useCartDrawerContext();

	return (
		<Button
			onClick={() => onToggle()}
			variant="ghost"
			className="flex flex-row gap-2 justify-center items-center text-center align-middle"
		>
			<ShoppingCartIcon size={20} className="mr-2" />
			<Typography>{itemsQty}</Typography>
		</Button>
	);
}
