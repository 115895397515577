"use client";

import { Typography } from "@/components/ui/components/typography";
import { useUrlParams } from "@/hooks/use-url-params";
import { ProductAttribute } from "../types";
import { Button } from "@/components/ui/components/button";
import { useState } from "react";
import { cn } from "@/lib/utils";

type ProductColorSelectorType = {
	label: string;
	colors: string[];
	initialColorSelected: string;
};

export const ProductColorSelector = ({
	label,
	colors,
	initialColorSelected,
}: ProductColorSelectorType) => {
	const { setParams, isPending } = useUrlParams();
	const [colorSelected, setColor] = useState<string>(initialColorSelected);

	return (
		<div className="asio-color-selector__container">
			<div className="asio-color-selector__label-container">
				<Typography.Heading as="h5" className="asio-color-selector__label">
					{label}
				</Typography.Heading>
				<Typography.Text as="p" className="asio-color-selector__label-text">
					{colorSelected}
				</Typography.Text>
			</div>
			<div className="flex space-x-4">
				{(colors || []).map((color, index) => (
					<Button
						key={`${color}-${index}`}
						className={cn(
							"asio-color-selector__option",
							colorSelected === color && "asio-variant-selector__selected"
						)}
						style={{
							backgroundColor: `${color}`,
						}}
						variant="circle"
						disabled={isPending}
						onClick={() => {
							setParams(ProductAttribute.Color, color);
							setColor(color);
						}}
					/>
				))}
			</div>
		</div>
	);
};
