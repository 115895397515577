"use client";

import {
	EmbeddedCheckout,
	EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

import { StripeCardElementOptions, loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createCheckoutSessionAction } from "@/commercetools/actions/payment.actions";
import { LineItem } from "@/shared/types/cart/LineItem";

const stripeKey = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
const stripe = stripeKey ? loadStripe(stripeKey) : null;

export const StripeCheckoutExpress = ({
	lineItems,
}: {
	lineItems: LineItem[];
}) => {
	useEffect(() => {
		(async () => {
			const result: any = await createCheckoutSessionAction({
				locale: "en",
			});
			setSecret(result.data.client_secret);
		})();
	}, []);

	const [secret, setSecret] = useState(null);

	return (
		<div className="asio-payment-card-container">
			{secret && (
				<EmbeddedCheckoutProvider
					stripe={stripe}
					options={{ clientSecret: secret }}
				>
					<EmbeddedCheckout />
				</EmbeddedCheckoutProvider>
			)}
		</div>
	);
};
