import { ProductAttribute, SearchParams } from "@/components/product/types";
import {
	extractAttributesValues,
	filterVariantByAttributes,
	replaceAttributeKeys,
} from "@/lib/variants";
import { Product } from "@/shared/types/product";
import { useMemo } from "react";

export const useProductDetails = (
	_product: Product,
	searchParams?: SearchParams
) => {
	const product = useMemo(() => replaceAttributeKeys(_product), [_product]);

	const variants = useMemo(
		() => (product && product.variants) || [],
		[product]
	);

	const colors = useMemo(
		() =>
			(product &&
				extractAttributesValues<string>(product, ProductAttribute.Color)) ||
			[],
		[product]
	);

	const stockByColors: Record<string, string[]> = {};

	colors.forEach((color) => {
		stockByColors[color] = [];
	});

	Object.keys(stockByColors).forEach((color) => {
		const variantsFiltered =
			variants.filter(
				(variant) =>
					variant.attributes &&
					variant.attributes[ProductAttribute.Color] === color &&
					variant.isOnStock
			) || [];

		variantsFiltered.forEach((variant) => {
			if (variant.attributes && variant.attributes[ProductAttribute.Size]) {
				stockByColors[color].push(variant.attributes[ProductAttribute.Size]);
			}
		});
	});

	const variant = useMemo(() => {
		if (searchParams && Object.keys(searchParams).length > 0) {
			return filterVariantByAttributes(product, searchParams);
		} else {
			return variants[0];
		}
	}, [variants, searchParams, product]);

	const images = useMemo(
		() => (variant.images && variant.images.map((image) => image)) || [],
		[variant.images]
	);

	return {
		product,
		variants,
		variant,
		images,
		stockByColors,
	};
};
