"use client";

import { Button } from "@/components/ui/components/button";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import type { Options } from "react-markdown";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Typography } from "@/components/ui/components/typography";

export const ProductDetailDescription = ({ className, ...props }: Options) => {
	const [isShowMoreActived, setIsShowMoreActived] = useState(false);

	const toggleShowMore = () => {
		setIsShowMoreActived((prevState) => !prevState);
	};

	return (
		<div className={cn("asio-product-description__container", className)}>
			{props.children ? (
				<>
					<ReactMarkdown
						className={cn(
							"asio-product-description_container-text-container",
							isShowMoreActived &&
								"asio-product-description__container-text-container--expanded"
						)}
						rehypePlugins={[rehypeRaw]}
						{...props}
					/>
					<Button
						variant="link"
						size="custom"
						onClick={toggleShowMore}
						className={cn("asio-product-description__container-button")}
					>
						{isShowMoreActived ? "Read less" : "Read more"}
					</Button>
				</>
			) : (
				<Typography.Text>Loading...</Typography.Text>
			)}
		</div>
	);
};