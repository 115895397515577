"use client";

import { TasticProps } from "@/commercetools/tastics";
import { DataSource } from "@/commercetools/types/data-sources";
import { Product } from "@/shared/types/product";
import { Facet } from "@/shared/types/result";
import { PLPHeader } from "@/components/product/list/plp-header";
import { PageProps } from "@/commercetools/types/next.types";
import Link from "next/link";
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	CardFooter,
	CardPrice,
} from "@/components/ui/components/card";
import { formatText } from "@/lib/utils";
import { useProductDetails } from "@/commercetools/hooks/use-product-details";
import { ProductAttribute } from "@/components/product/types";
import { extractAttributesValues } from "@/lib/variants";
import { useMemo } from "react";
import { Divider } from "@/components/ui/components/divider";
import { Image } from "@/components/ui/components/image";
import { ProductCard } from "@/components/product/card";

type DataSourceProps = {
	items: Product[];
	category: string;
	facets: Facet[];
	previousCursor?: string;
	nextCursor?: string;
	total: number;
	totalItems: number;
};

function searchByAttribute(
	items: Product[] | undefined,
	attribute: string,
	value: string
) {
	if (!items) {
		return [];
	}

	return items.filter((item) => {
		const attributes = item.variants[0].attributes;
		return attributes && attributes[attribute] === value;
	});
}

const EmptySearchResults = () => {
	return (
		<div className="flex flex-col items-center py-10 px-3 space-y-6">
			<h1 className="text-center text-4xl font-bold">
				We’re sorry, no results found.
			</h1>
			<div className="w-3/5">
				<p className="text-center">
					Try adjusting your filters for better results.
					<p className="text-center">
						Need more help? Contact us at{" "}
						<a
							href="mailto:info@ariessolutions.io"
							aria-label="Email us"
							className="hover:underline"
						>
							info@ariessolutions.io
						</a>
						.
					</p>
				</p>
			</div>
		</div>
	);
};

export default function ProductsListTastic({
	data,
	searchParams,
	params,
}: TasticProps<DataSource<DataSourceProps & PageProps>>) {
	let items = data?.data?.dataSource?.items || [];

	for (let keys in Object.keys(searchParams)) {
		items = searchByAttribute(
			items,
			Object.keys(searchParams)[keys],
			searchParams[Object.keys(searchParams)[keys]] as string
		);
	}

	if (items.length === 0) return <EmptySearchResults />;

	return (
		<div className="flex flex-col p-4 container">
			<PLPHeader sortEnabled={false} count={20} searchParams={searchParams} />
			<div className="grid grid-cols-2 md:grid-cols-4 gap-4 pt-4">
				{items.map((item, index) => (
					<ProductCard key={`${item.productId}-${index}`} item={item} />
				))}
			</div>
		</div>
	);
}
