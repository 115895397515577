import { Label } from "@/components/ui/components/label";
import { FilterOption } from "@/components/product/filters/filter-component";
import { PropsWithChildren } from "react";

export function CustomCheckbox({
	name,
	value,
	children,
	handleClick,
	defaultChecked,
}: PropsWithChildren<{
	name?: string;
	value: string;
	handleClick?: (e: React.MouseEvent) => void;
	defaultChecked?: boolean;
}>) {
	return (
		<Label className="flex items-center gap-2 font-normal">
			<input
				name={name}
				type="checkbox"
				value={value}
				className="h-4 w-4"
				defaultChecked={defaultChecked || false}
				onClick={handleClick}
			/>
			{children}
		</Label>
	);
}

export function FilterCheckbox({
	name,
	options,
	defaultValues = [],
	handleChange,
}: {
	name: string;
	options: FilterOption[];
	defaultValues?: string[];
	handleChange: (e: any) => void;
}) {
	return (
		<div className="grid gap-2">
			{options.map((option) => (
				<CustomCheckbox
					key={option.value}
					name={name}
					value={option.value}
					defaultChecked={defaultValues.includes(option.value)}
					handleClick={handleChange}
				>
					{option.component}
				</CustomCheckbox>
			))}
		</div>
	);
}
