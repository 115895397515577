import { ProductAttribute } from "@/components/product/types";
import { Product, Variant } from "@/shared/types/product";

type VariantType = { [key: string]: string | number };

/*
 * List of product attributes define on Merchant
 */
export const productAttributes = [
	ProductAttribute.Size,
	ProductAttribute.Color,
];

export function findVariant(
	variant: VariantType,
	variants: Variant[]
): Variant[] {
	const keys = Object.keys(variant);

	return variants.filter((v) => keys.some((k) => Object.keys(v).includes(k)));
}

/**
 * Replace attributes keys buy standard products' keys
 * For example: ['mountain-shoes-color', 'mountain-shoes-size'] -> ['color', 'size']
 * @param product
 * @param filters ['color', 'size']
 * @returns
 */
export function replaceAttributeKeys(product: Product): Product {
	const updatedVariants = product.variants.map((variant) => {
		const updatedAttributes: { [key: string]: any } = {};

		variant.attributes &&
			Object.entries(variant.attributes).forEach(([key, value]) => {
				const matchingFilter = productAttributes.find((filter) =>
					key.includes(filter)
				);
				if (matchingFilter) {
					const newKey = matchingFilter;
					updatedAttributes[newKey] = value;
				} else {
					updatedAttributes[key] = value;
				}
			});

		return { ...variant, attributes: updatedAttributes };
	});

	return { ...product, variants: updatedVariants };
}

/**
 * Replace attributes keys buy standard products' keys
 * For example: ['mountain-shoes-color', 'mountain-shoes-size'] -> ['color', 'size']
 * @param product
 * @param filters ['color', 'size']
 * @returns
 */
export function replaceAttributeKeysOnVariant(variant: Variant): Variant {
	const updatedAttributes: { [key: string]: any } = {};
	variant.attributes &&
		Object.entries(variant.attributes).forEach(([key, value]) => {
			const matchingFilter = productAttributes.find((filter) =>
				key.includes(filter)
			);
			if (matchingFilter) {
				const newKey = matchingFilter;
				updatedAttributes[newKey] = value;
			} else {
				updatedAttributes[key] = value;
			}
		});

	return { ...variant, attributes: updatedAttributes };
}

/**
 * Purgue values that are not attributes
 * @param object with search params key and values
 * @param allowedAttributes ['color', 'size']
 * @returns
 */
function filterAttributes(
	searchParams: Record<string, any>,
	allowedAttributes: string[]
): Record<string, any> {
	const result: Record<string, any> = {};

	Object.entries(searchParams).forEach(([key, value]) => {
		if (allowedAttributes.includes(key)) {
			result[key] = value;
		}
	});

	return result;
}

export function filterVariantByAttributes(
	product: Product,
	filters: Record<string, any>
): Variant {
	const allowedAttributes = filterAttributes(filters, productAttributes);

	const matchingVariant = product.variants.find((variant) => {
		return Object.entries(allowedAttributes).every(([key, value]) => {
			if (variant.attributes) {
				const attributeValue = variant.attributes[key];

				if (Array.isArray(attributeValue)) {
					return attributeValue.includes(
						typeof value === "string" ? parseInt(value) : value
					);
				}

				return attributeValue === value;
			}
		});
	});

	if (!matchingVariant) {
		throw new Error("No matching variant found for the given filters.");
	}

	return matchingVariant;
}

export function extractAttributesValues<T>(product: Product, key: string): T[] {
	const attributesValues: T[] = [];

	product.variants.forEach((variant) => {
		const value = variant.attributes && variant.attributes[key];
		if (value !== undefined && !attributesValues.includes(value)) {
			attributesValues.push(value);
		}
	});

	return attributesValues;
}
