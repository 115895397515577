"use client";

import { EmblaCarousel } from "@/components/ui/components/carousel";
import { Image } from "@/components/ui/components/image";
import { EmblaOptionsType } from "embla-carousel";
import { useEffect, useState } from "react";

type GalleryProps = {
	images: string[];
	handleSelectImage?: (image: string) => void;
};

const OPTIONS: EmblaOptionsType = { loop: true };

export const Gallery = ({ images, handleSelectImage }: GalleryProps) => {
	return <EmblaCarousel images={images} options={OPTIONS} />;
};
