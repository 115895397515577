import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/components/form";
import { Input } from "@/components/ui/components/input";
import { useFormContext } from "react-hook-form";
import { PasswordFormSchema } from "./schema";

export function PasswordForm() {
	const form = useFormContext<PasswordFormSchema>();

	return (
		<div>
			<div className="grid grid-cols-1 gap-4 font-poppins mt-8">
				<FormField
					control={form.control}
					name="password.currentPassword"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Current Password</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Current password"
									type="password"
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password.newPassword"
					render={({ field }) => (
						<FormItem>
							<FormLabel>New Password</FormLabel>
							<FormControl>
								<Input {...field} placeholder="New password" type="password" />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password.confirmPassword"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Repeat New Password</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Confirm password"
									type="password"
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
			</div>
		</div>
	);
}
