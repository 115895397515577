"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Image } from "@/components/ui/components/image";
import { Typography } from "@/components/ui/components/typography";
import Link from "next/link";

type Media = {
	file: string;
	alt: string;
};

type TasticData = {
	eyebrow?: string;
	title?: string;
	description?: string;
	background?: {
		media: Media;
	};
	callToAction: [
		{
			name: string;
			reference: {
				link: string;
				openInNewWindow: boolean;
				type: string;
			};
		}
	];
};

export default function HeroWithBackgroundTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div className="landing__hero_main_container">
			<div className="landing__hero_main_image_container">
				{data?.background && (
					<Image
						src={data?.background?.media?.file}
						alt={data?.background?.media?.alt}
						fill
						className="landing__hero_main_image"
					/>
				)}
			</div>
			<div className="landing__hero_main_image_overlay"></div>
			<div className="landing__hero_main_content_container">
				{data?.eyebrow && (
					<Typography.Text className="landing__hero_main_eyebrow">
						{data?.eyebrow}
					</Typography.Text>
				)}
				{data?.title && (
					<Typography.Heading as="h1" className="landing__hero_main_title">
						{data?.title}
					</Typography.Heading>
				)}
				{data?.description && (
					<Typography.Text className="landing__hero_main_description">
						{data?.description}
					</Typography.Text>
				)}
				{data?.callToAction[0]?.name && (
					<Link
						href={
							data?.callToAction[0]?.reference?.link
								? data?.callToAction[0]?.reference?.link
								: "#"
						}
						className="asio-hero-main__button"
						target={
							data?.callToAction[0]?.reference?.openInNewWindow
								? "_blank"
								: "_self"
						}
					>
						{data?.callToAction[0]?.name}
					</Link>
				)}
			</div>
		</div>
	);
}
