import React from "react";
import { Image } from "../image";
import { Button } from "../button";
import "./styles.css";

type PropType = {
	selected: boolean;
	imgSrc: string;
	index: number;
	onClick: () => void;
};

export const Thumb: React.FC<PropType> = (props) => {
	const { selected, imgSrc, index, onClick } = props;

	return (
		<div
			className={"embla-thumbs__slide".concat(
				selected ? " embla-thumbs__slide--selected" : ""
			)}
		>
			<Button
				onClick={onClick}
				className="embla-thumbs__slide__button"
				type="button"
			>
				<Image
					className="embla-thumbs__slide__img"
					src={imgSrc}
					alt="Your alt text"
					width={100}
					height={100}
				/>
			</Button>
		</div>
	);
};
