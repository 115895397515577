"use client";

import { Button } from "@/components/ui/components/button";
import { FC } from "react";

export interface DisplayAddressProps {
	firstName: string;
	lastName: string;
	streetName: string;
	streetNumber: string;
	city: string;
	postalCode: string;
	state: string;
	country: string;
}

export const DisplayAddress: FC<DisplayAddressProps> = (props) => {
	const {
		firstName,
		lastName,
		streetName,
		streetNumber,
		city,
		postalCode,
		state,
		country,
	} = props;
	return (
		<div>
			<div className="space-x-1">
				<span>{firstName}</span>
				<span>{lastName}</span>
			</div>
			<div className="space-x-1">
				<span>{streetNumber}</span>
				<span>{streetName}</span>
			</div>
			<div>
				<span>{city}</span>
				<span>,</span>
				<span className="px-1">{state}</span>
				<span>{postalCode}</span>
			</div>
			<div>{country}</div>
			<div className="space-x-2 my-3">
				<Button>Edit</Button>
				<Button>Delete</Button>
			</div>
		</div>
	);
};
