"use client";

import { TasticProps } from "@/commercetools/tastics";
import { Typography } from "@/components/ui/components/typography";
import Link from "next/link";

type TasticData = {
	eyebrow?: string;
	title?: string;
	description?: string;
	callToAction: [
		{
			name: string;
			reference: {
				link: string;
				openInNewWindow: boolean;
				type: string;
			};
		}
	];
};

export default function HeroWithSinglePanelTastic({
	data,
}: TasticProps<TasticData>) {
	return (
		<div className="landing__hero_single_panel_container">
			{data?.eyebrow && (
				<Typography.Text className="landing__hero_single_panel_eyebrow">
					{data.eyebrow}
				</Typography.Text>
			)}
			{data?.title && (
				<Typography.Text className="landing__hero_single_panel_title">
					{data.title}
				</Typography.Text>
			)}
			{data?.description && (
				<Typography.Text className="landing__hero_single_panel_description">
					{data.description}
				</Typography.Text>
			)}
			{data?.callToAction[0]?.name && (
				<Link
					href={
						data?.callToAction[0]?.reference?.link
							? data?.callToAction[0]?.reference?.link
							: "#"
					}
					className="asio-hero-single-panel__button"
					target={
						data?.callToAction[0]?.reference?.openInNewWindow
							? "_blank"
							: "_self"
					}
				>
					{data?.callToAction[0]?.name}
				</Link>
			)}
		</div>
	);
}
