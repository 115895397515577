import { nonEmptyString } from "@/lib/utils";
import * as z from "zod";

export const accountSchema = z.object({
	firstName: nonEmptyString,
	lastName: nonEmptyString,
	email: z.string().email(),
});

export const accountFormSchema = z.object({
	account: accountSchema,
});

export type AccountSchema = z.infer<typeof accountSchema>;
export type AccountFormSchema = z.infer<typeof accountFormSchema>;
