import Link from "next/link";
import { usePathname } from "next/navigation";

export default function AccountNav() {
	const pathname = usePathname();

	const isActive = (url: string) => pathname.includes(url);

	return (
		<ul className="flex justify-center space-x-4 py-20 font-semibold uppercase tracking-wider">
			<li
				className={`hover:underline hover:underline-offset-8 ${
					isActive("/my-account/account") ? "underline underline-offset-8" : ""
				}`}
			>
				<Link href="/my-account/account">Account</Link>
			</li>
			<li
				className={`border-l border-slate-300 pl-4 hover:underline hover:underline-offset-8 ${
					isActive("/my-account/change-password")
						? "underline underline-offset-8"
						: ""
				}`}
			>
				<Link href="/my-account/change-password">Password</Link>
			</li>
			<li
				className={`border-x border-slate-300 px-4 hover:underline hover:underline-offset-8 ${
					isActive("/my-account/my-orders")
						? "underline underline-offset-8"
						: ""
				}`}
			>
				<Link href="/my-account/my-orders">Orders</Link>
			</li>
			<li
				className={`hover:underline hover:underline-offset-8 ${
					isActive("/my-account/my-addresses")
						? "underline underline-offset-8"
						: ""
				}`}
			>
				<Link href="/my-account/my-addresses">Addresses</Link>
			</li>
		</ul>
	);
}
